import React from "react";
import "./CustomInput.css";

const CustomInput = ({
  type = "text",
  placeholder = "Enter Text",
  //   borderColor = "#000",
  borderRadius = "4px",
  onChange,
  onFocus,
  onBlur,
  width,
  height,
  containerWidth,
  border,
  fontSize,
  fontFamily,
  fontSizeLabel,
  fontFamilyLabel,
  fontLabelColor,
  labelOpcaity,
  paddingLeft,
  marginTop,
}) => {
  return (
    <div
      style={{
        width: containerWidth || "100%",
      }}
      className="input-container"
    >
      <input
        type={type}
        placeholder=" " // Use an empty placeholder to ensure the label moves up
        className="input-box"
        style={{
          border: border || "1px solid #000",
          borderRadius: borderRadius,
          width: width || "100%",
          height: height || "40px",
          fontSize: fontSize || "16px",
          fontFamily: fontFamily || "Inter",
          paddingLeft: paddingLeft || "",
        }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <label
        style={{
          fontSize: fontSizeLabel || "16px",
          fontFamily: fontFamilyLabel || "Inter",
          color: fontLabelColor || "#000",
          labelOpcaity: labelOpcaity || "100%",
          marginTop: marginTop || "",
        }}
        className="input-label"
      >
        {" "}
        {placeholder}{" "}
      </label>
    </div>
  );
};

export default CustomInput;
