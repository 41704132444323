import React from "react";
import "../styles/PackListing.css";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import config from "../utils/config";
import PackCard from "../components/CardsComponent/PackCard/PackCard";
import AllPackCard from "../components/CardsComponent/PackCard/AllPackCard";
import RandomDefaultImage from "../utils/RandomDefaultImage";
import { Link } from "react-router-dom";
import CustomImg from "../components/CustomImg/CustomImg";


const PackListing = () => {
  const offerCards = [
    {
      image: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/packs/banner1.webp`,
      title: "Trendsetters",
      description: "Fresh tracks, trending vibes! Explore the latest hits from Bollywood's finest. License premium tracks for your next project.",
      buttonText: "Explore Pack",
      packCode: 12,
      packNameSlug: "trendsetters",
      titleImg: 'https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/packs/trendsetters.webp'
    },
    {
      image: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/packs/banner2.webp`,
      title: "Where’s the Party?",
      description: "Make your year-end celebrations unforgettable. License the perfect track for every occasion.",
      buttonText: "Explore Pack",
      packCode: 15,
      packNameSlug: "wheres-the-party",
      titleImg: "https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/packs/party.webp"
    },
  ];

  const packCards = [
    {
      image: "https://cdn.staging.select.hoopr.ai/playlist/1a599cf2-ec70-4978-b488-27ae3c7b18b0/cover.webp",
      title: "Big Bang Chartbusters",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 15,
      packNameSlug: "big-bang-chartbusters",
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/playlist/7363c65d-0bd0-4d6a-85b3-ee12c3f0bf81/cover.webp`,
      title: "Desi Devotion",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 16,
      packNameSlug: "desi-devotion",
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/playlist/d9ae44b8-b30e-4102-b6ab-324dc87239ea/cover.webp`,
      title: "Happily Ever After",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 13,
      packNameSlug: "happily-ever-after",
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/playlist/28d3bbc4-3aeb-4539-bed2-d86522e9897b/cover.webp`,
      title: "I Love My India",
      buttonText: "Explore",
      totalTracks: 4,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 10,
      packNameSlug: "i-love-my-india",
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/playlist/62eaab02-13d1-4180-886c-9a8eca3454fb/cover.webp`,
      title: "Pulse Racers",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 17,
      packNameSlug: "pulse-racers",
    },
    {
      image: `https://cdn.staging.select.hoopr.ai/playlist/74724faa-c651-452b-8be6-ba2102bf5c20/cover.webp`,
      title: "Love Is In The Air",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 11,
      packNameSlug: "love-is-in-the-air",
    },
  ];

  return (
    <div className="pack-listing-container">
      {/* packs page description */}
      <div className="pack-listing-description outer">
        <h1>Shop by Packs</h1>
        <h2>
        Discover a collection of the perfect tracks for your project. Browse our extensive library of music packs, categorized by occasion and use cases. Whether you're creating a commercial, a social media post, or a full-length film, seamlessly license the music you need for your content.
        </h2>
      </div>
      {/* top packs card section */}
      <div className="toppack-container outer">
        {offerCards.map((offer, index) => (
          <div
            key={index}
            style={
              {
                backgroundImage: `url(${offer.image})`,
              }
            }
            className="toppack-card-img"
          >
            <div className="toppack-card-title">
              {/* <h1> */}
              {/* <CustomImg
                  type="pack"
                  id="party_title"
                  alt="Where's the Party?"
                  imgClass="pack-banner-title-image"
                  src={ offer.titleImg }
                /> */}
              {/* </h1> */}
            </div>
            {/* {/* <div className="toppack-card-description">
              <h2>{offer.description}</h2>
            </div> */}
            <Link
                  to={`/playlists/pack-${encodeURIComponent(
                    offer.packNameSlug
                  )}/${offer.packCode}`}
                >
            <div>
              <CustomButton
                text={offer.buttonText}
                backgroundColor="#fff"
                borderRadius="30px"
                borderColor="none"
                width="182px"
                height="37px"
                textColor="#C6349B"
                iconColor="#C6349B"
                fontSize="16px"
                fontFamily="Raleway"
                fontWeight="bold"
                icon={<IoIosArrowForward />}
                justifyContent="space-between"
              />
              </div>
              </Link>
          </div>
        ))}
      </div>
      {/* popular packs */}
      <div className="packs-container outer-full">
        <div className="inner inner-pl">
          <h1 className="packs-container-heading">Explore Popular Packs</h1>
          <div className="packs-description-view">
            <h2>Multi-track packs for all occasions at great savings!</h2>
            {/* <div className="packs-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div> */}
          </div>
          <div className="inner">
            <PackCard packCards={packCards} />
          </div>
        </div>
      </div>
      {/* all packs section */}
      <div style={{ marginTop: "10px" }}>
        <AllPackCard />
      </div>
    </div>
  );
};

export default PackListing;
