import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import Header from "./screens/Header";
import Home from "./screens/Home";
import TrackPage from "./screens/TrackPage";
import TrackListing from "./screens/TrackListing";
import PackListing from "./screens/PackListing";
import PackPage from "./screens/PackPage";
import Footer from "./screens/Footer";
import GlobalPlayer from "./screens/GlobalPlayer";
import { GlobalPlayerProvider } from "./utils/GlobalPlayerContext";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Faq from "./screens/Faq";
import CategoryPage from "./screens/CategoryPage";
import Moodspage from "./screens/MoodsPage";
import GenrePage from "./screens/GenrePage";
import SearchPage from "./screens/SearchPage";
import MoodsListing from "./screens/MoodsListing";
import CategoryListing from "./screens/CategoryListing";
import GenreListing from "./screens/GenreListing";
import { AuthProvider, useAuth } from "./utils/AuthContext";
import Checkout from "./screens/Checkout";
import { ToastProvider } from "./utils/ToastContext";
import ToastComponent from "./components/ToastComponent/ToastComponent";

const ProtectedRoutes = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tracks/:name/:trackCode" element={<TrackPage />} />
      <Route path="/tracks" element={<TrackListing />} />
      <Route path="/packs" element={<PackListing />} />
      <Route path="/playlists/:packNameSlug/:packCode" element={<PackPage />} />
      <Route
        path="/categories/:name/:categoryCode"
        element={<CategoryPage />}
      />
      <Route path="/moods/:name/:moodsCode" element={<Moodspage />} />
      <Route path="/genres/:name/:genreCode" element={<GenrePage />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/faqs" element={<Faq />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/moods" element={<MoodsListing />} />
      <Route path="/categories" element={<CategoryListing />} />
      <Route path="/genres" element={<GenreListing />} />
      <Route path="/checkout" element={<Checkout />} />
    </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <ToastProvider>
          <ToastComponent />
          <GlobalPlayerProvider>
            <div className="parentDiv">
              {/* Always visible component */}
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  left: 0,
                  zIndex: 1,
                }}
              >
                <Header />
              </div>

              {/* Route-based pages */}
              <ProtectedRoutes />
              {/* <Routes>
              <Route path="/" element={<Home />} />
            <Route path="/tracks/:name/:trackCode" element={<TrackPage />} />
            <Route path="/tracks" element={<TrackListing />} />
            <Route path="/packs" element={<PackListing />} />
            <Route
              path="/playlists/:packNameSlug/:packCode"
              element={<PackPage />}
            />
            <Route
              path="/categories/:name/:categoryCode"
              element={<CategoryPage />}
            />
            <Route path="/moods/:name/:moodsCode" element={<Moodspage />} />
            <Route path="/genres/:name/:genreCode" element={<GenrePage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/moods" element={<MoodsListing />} />
            <Route path="/categories" element={<CategoryListing />} />
            <Route path="/genres" element={<GenreListing />} />
            </Routes> */}

              {/* Always visible component */}
              <Footer />
              <GlobalPlayer />
            </div>
          </GlobalPlayerProvider>
        </ToastProvider>
      </Router>
    </AuthProvider>
  );
};

export default App;
