import axios from "axios";
import { urls } from "../utils/AppManager";
import config from "../utils/config";
import CleanSearchKey from "../utils/CleanSearchKey";

export const getSearchResult = async (query) => {
  query = CleanSearchKey(query);
  const url = `${config.baseUrl}${urls.getSearchResult}?searchKeyword=${query}`;
  try {
    const res = await axios.post(url);
    return res.data;
  } catch (error) {
    console.error("Error fetching tracks:", error);
    return { data: [] };
  }
};

export const getSearchResultFaceted = async (query, payload, page) => {
  query = CleanSearchKey(query);
  const url = `${config.baseUrl}${urls.getSearchResultfaceted}/paginated?searchKeyword=${query}&pageNumber=${page}`;
  try {
    const res = await axios.post(url, payload);
    return res.data;
  } catch (error) {
    console.error("Error fetching tracks:", error);
    return { data: [] };
  }
};
