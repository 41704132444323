import React, { useEffect } from "react";
import styles from "../styles/GenreListing.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../utils/redux/trackFiltersSlice";
import { Link } from "react-router-dom";
import CustomImg from "../components/CustomImg/CustomImg";

const MoodsListing = () => {
  const dispatch = useDispatch(); // action to refresh the filters mood, genre, usecase
  const { genres, status } = useSelector((state) => state.trackFilters);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  const categories = {
    genres: genres.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
  };
  // console.log(categories, "genres");

  // Dynamically generate allgenres from categories
  const allGenres = categories.genres.map((genre, index) => ({
    id: genre.id,
    image: randomDefaultImage("genre"),
    heading: genre.title,
    slug: genre.slug,
  }));

  // console.log(allGenres, "allGenres");

  // Utility to pick 4 random genres from allGenres
  // const pickRandomGenres = (genres, count = 4) => {
  //   const shuffled = [...genres].sort(() => 0.5 - Math.random());
  //   return shuffled.slice(0, count);
  // };

  // Utility to filter genres by title
  const pickGenresByTitle = (genres, titles) => {
    const titleSet = new Set(titles.map((title) => title.toLowerCase()));
    return genres.filter((genre) => titleSet.has(genre.heading.toLowerCase()));
  };

  // Randomly picked genres
  // const randomGenres = pickRandomGenres(allGenres);

  // Specifically picked genres by title
  const specificTitles = ["Bollywood Classic", "Punjabi Folk", "Haryanvi"]; // Example titles
  const specificGenres = pickGenresByTitle(allGenres, specificTitles);

  const popularGenres = specificGenres; // Set popularMoods as randomly picked moods or specificMoods based on requirement

  // Function to find a genres by title for main banner
  // Function to find genres by title
  const findGenresByTitle = (genres, title) => {
    return genres.find(
      (genre) => genre.heading.toLowerCase() === title.toLowerCase()
    );
  };

  // Example: Find "Bihu" genres
  // const specificGenre = findGenresByTitle(allGenres, "Bihu");
  const specificGenre = 'Bollywood Contemporary';

  return (
    <div className={styles.genresListingContainer}>
      {/* genres page description */}
      <div className={`${styles.genresListingDescription} ${styles.outer}`}>
        <h1 className={styles.genresHeading}>Shop By Genres</h1>
        <h2 className={styles.genresSubHeading}>
        Find that distinctive vibe for your content! Explore popular tracks across 100+ genres to enhance relatability and elevate the emotional quotient.
        </h2>
      </div>
      {/* genres main banner */}
      <div
        style={{
          backgroundImage: `url(${randomDefaultImage("track")})`,
        }}
        className={`${styles.genresListingBanner} ${styles.inner}`}
      >
        <h1 className={styles.genresBannerHeading}>
          {/* {specificGenre ? specificGenre.heading : "Genre Name"} */}
          {specificGenre}
        </h1>
        <h2 className={styles.genresBannerSubHeading}>
        Authentic and traditional music from various cultures around the world.
        </h2>
        <Link
          to={`/genres/${encodeURIComponent(
            specificGenre ? specificGenre.slug : ""
          )}/${specificGenre ? specificGenre.id : ""}`}
        >
          <CustomButton
            text="Explore Track"
            backgroundColor="#fff"
            borderRadius="50px"
            borderColor="none"
            width="182px"
            height="37px"
            textColor="#C6349B"
            fontSize="16px"
            fontFamily="Raleway"
            fontWeight="bold"
            iconColor="#C6349B"
            icon={<IoIosArrowForward />}
            justifyContent="space-between"
          />
        </Link>
      </div>
      {/* popular genres section */}
      <div className={`${styles.populargenresContainer} ${styles.outer}`}>
        <h1 className={styles.populargenresHeading}>Popular Genres</h1>
        <div className={styles.populargenresCards}>
          {/* for handling more card css is added just update the slice value */}
          {popularGenres.slice(0, 3).map((genre) => (
            <Link
              // key={index}
              to={`/genres/${encodeURIComponent(genre.slug)}/${genre.id}`}
            >
              <div key={genre.id} className={styles.populargenresCard}>
                {/* <img
                  src={genre.image}
                  alt={genre.heading}
                  className={styles.populargenresCardImage}
                /> */}
                <CustomImg
                  type="genre"
                  id={genre.id}
                  alt={genre.heading}
                  imgClass={styles.populargenresCardImage}
                  src=""
                >
                </CustomImg>
                <h1 className={styles.populargenresCardHeading}>
                  {genre.heading}
                </h1>
              </div>

            </Link>
          ))}
        </div>
      </div>
      {/* all genres section */}
      <div className={`${styles.populargenresContainer} ${styles.outer}`}>
        <h1 className={styles.populargenresHeading}>All Genres</h1>
        <div className={styles.allgenresCards}>
          {/* for handling more card css is added just update the slice value */}
          {allGenres.map((genre) => (
            <Link
              // key={index}
              to={`/genres/${encodeURIComponent(genre.slug)}/${genre.id}`}
            >
              <div key={genre.id} className={styles.allgenresCard}>
                <CustomImg
                  type="genre"
                  id={genre.id}
                  alt={genre.heading}
                  imgClass={styles.populargenresCardImage}
                  src=""
                >
                </CustomImg>
                <h1 className={styles.allgenresCardHeading}>{genre.heading}</h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoodsListing;
