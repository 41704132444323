import React from "react";
import { IconContext } from "react-icons";
import CustomArrow from "./CustomArrow";
import "./CustomButton.css";

const CustomButton = ({
  text,
  icon,
  backgroundColor,
  iconColor,
  iconSize,
  borderColor,
  textColor,
  borderRadius,
  onClick,
  isIconOnly,
  hoverEffect,
  height,
  fontSize,
  fontWeight,
  buyArrow,
  justifyContent,
  width,
  fontFamily,
}) => {
  return (
    <button
      className={`custom-button ${hoverEffect ? "hover-effect" : ""} ${
        isIconOnly ? "icon-only" : ""
      }`}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor || "transparent",
        color: textColor || (backgroundColor ? "#fff" : "#000"),
        border: borderColor || (borderColor ? "none" : "#000"),
        borderRadius: borderRadius || "4px",
        height: height || "40px", // Fixed height for all buttons
        width: width || "auto", // auto width default
        fontSize: fontSize || "14px",
        fontWeight: fontWeight || "normal",
        fontFamily: fontFamily || "Inter",
        justifyContent: justifyContent || "center",
        position: "relative", // Ensure the arrow stays within the button added for custom arrrow component
        overflow: "hidden", // Prevent overflow of arrow icons added for custom arrrow component
      }}
    >
      {!isIconOnly && text}
      {buyArrow ? (
        <div className="triple-arrow-icon">
          <CustomArrow />
        </div>
      ) : (
        icon && (
          <IconContext.Provider
            value={{
              size: iconSize || "20px",
              style: {
                marginLeft: text ? "8px" : "0",
                color: iconColor ? iconColor : "#000",
              },
            }}
          >
            {icon}
          </IconContext.Provider>
        )
      )}
    </button>
  );
};

export default CustomButton;
