import axios from "axios";
import { urls } from "../utils/AppManager";
import config from "../utils/config";

export const getAllPack = async (page = 1) => {
  const limit = 20;
  const url = `${config.baseUrl}${urls.getPack}?page=${page}&limit=${limit}&markerType=Packs`;
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.error("Error fetching tracks:", error);
    return { data: [] };
  }
};

export const getPack = async (packCode) => {
  const url = `${config.baseUrl}${urls.getPack}/${packCode}`;
  // console.log(url);
  const res = await axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};

export const getPackFilters = async () => {
  const url = `${config.baseUrl}${urls.getPackFilters}`;
  const res = await axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};
