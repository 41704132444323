import React from "react"
import './CustomArrow.css'

const CustomArrow = () => {
    return (
        <div className="container">
            <div className="box box1">&#10093;</div>
            <div className="box box2">&#10093;</div>
            <div className="box box3">&#10093;</div>
        </div>
    )
}

export default CustomArrow;