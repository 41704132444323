import React, { useState, useEffect, useRef } from "react";
import "../styles/TrackPage.css";
import config from "../utils/config";
import { FaPlay, FaPause } from "react-icons/fa";
import CustomButton from "../components/CustomButton/CustomButton";
import { FaRegHeart } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import { BiAddToQueue } from "react-icons/bi";
import { IoIosArrowForward, IoIosPause } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import {
  // IoIosArrowUp,
  IoIosArrowDown,
} from "react-icons/io";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useParams } from "react-router-dom";
import { getTrack } from "../service/TrackService";
import PackCard from "../components/CardsComponent/PackCard/PackCard";
import { useGlobalPlayer } from "../utils/GlobalPlayerContext";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { Link } from "react-router-dom";
import getDirectLink from "../utils/AudioUrlFormated";

const TrackPage = () => {
  const [openCards, setOpenCards] = useState([true, false, false]); // state to manage the cards opening state
  const [trackData, setTrackData] = useState([]); // state to hold the fetched track data
  const [trackMaxUsage, setTrackMaxUsage] = useState("2"); // short form video max usage for a track

  const { trackCode } = useParams(); // gettign the data from navigated route

  // Destructure the context
  const { isPlaying, setIsPlaying, showPlayer } = useGlobalPlayer();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTrack(trackCode);
        const data = response.data || [];
        // console.log(data, "data");
        // console.log(data.track.SKU[0].maxUsage);
        setTrackMaxUsage(data.track.SKU[0].maxUsage);
        setTrackData(data.track); // Set the track object in the state
        // console.log(data, "trackData");
      } catch (error) {
        console.error("Error fetching track data:", error);
      }
    };
    fetchData();
  }, [trackCode]); // calling the function when the trackCode changes

  // function for toggling the cards
  const toggleContainer = (index) => {
    setOpenCards((prevOpenCards) => {
      // Create a new array with the updated open states
      const newOpenCards = prevOpenCards.map((isOpen, i) =>
        i === index ? !isOpen : false
      );
      return newOpenCards;
    });
  };

  const aboutSong = [
    {
      title: "About",
      description: trackData.description || "",
    },
    {
      title: "Song Credits",
      description: `From "${trackData.albumDetails?.title || ""}" by ${
        trackData.primaryArtists
          ?.map((artist) => artist.artistName)
          .join(", ") || ""
      } ${
        trackData.trackRoleArtists
          ?.map((artist) => artist.artistName)
          .join(", ")
          ? "ft. " +
            trackData.trackRoleArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : ""
      } | ${trackData.releaseYear || ""} | ${trackData.ownerName || ""}.`,
    },
    {
      title: "Type",
      description: trackData.type
        ? trackData.type.charAt(0).toUpperCase() + trackData.type.slice(1)
        : "",
    },
    {
      title: "Category",
      pills: trackData.filters?.usecase?.map((usecase) => usecase.name) || [], // Map mood from filters
    },
    {
      title: "Genres",
      pills: trackData.filters?.genre?.map((genre) => genre.name) || [""], // Map genre from filters
    },
    {
      title: "Events",
      pills: trackData.events?.map((events) => events) || [""], // Map instrument as events
    },
    {
      title: "Language",
      pills: trackData.filters?.language?.map((language) => language.name) || [
        "",
      ], // Map language from filters
    },
  ];

  const packCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Packs",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Packs",
      buttonLink: "#",
      totalTracks: 12,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Packs",
      buttonLink: "#",
      totalTracks: 14,
    },
  ];

  const similarTracks = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Mere Mehboob",
      description: "Sachin Jigar",
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: "50%",
      costPrice: "79,000",
      sellingPrice: "19,999",
    },
  ];

  const tracksBenefits = [
    {
      image: `${config.s3imgUrl}icons/immediatedownload.png`,
      title: "Immediate Downlaod",
    },
    {
      image: `${config.s3imgUrl}icons/copyrightshield.png`,
      title: "100% Copyright Safe",
    },
    {
      image: `${config.s3imgUrl}icons/copyright.png`,
      title: "Zero-claims Guarantee",
    },
    {
      image: `${config.s3imgUrl}icons/globalnetwork.png`,
      title: "Worldwide Clearance",
    },
    {
      image: `${config.s3imgUrl}icons/chat.png`,
      title: "Dedicated Support Team",
    },
  ];

  const pricingBuyPackCards = [
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Packs",
      buttonLink: "#",
      totalTracks: 10,
    },
    {
      image: `${config.s3imgUrl}trackimages/sampleimage.png`,
      title: "Happy Diwali",
      buttonText: "Explore Packs",
      buttonLink: "#",
      totalTracks: 12,
    },
  ];

  const shortPros = {
    // pros: [
    //   `${trackMaxUsage} use instances allowed`,
    //   "Quick clearance on submission of usage links",
    //   "Clearance only for IG Reels and YT Shorts",
    //   "Publish content within license validity duration",
    // ],
    pros: [
      "Clearance only for IG Reels and YT Shorts",
      `Allowed to use in only ${trackMaxUsage} videos`,
      // "Quick clearance on submission of usage links",
      // "Content to be published within 12 months of purchase",
      "Cleared for only one brand/entity",
    ],
    // cons: [
    //   "No clearance for long form content, OTT and broadcast",
    //   "No clearance for DVC and paid ads",
    //   "No clearance for influencer campaigns",
    // ],
    cons: [
      "Not allowed to use in long-form videos",
      // "Not allowed to use on OTT, broadcast (TV, video)",
      "Not allowed to use on OTT and broadcast",
      // "Not allowed for influencer activities on any platform",
      "No influencer use allowed on any platform",
      // "Not allowed to create derivatives or use in any derogatory or defamatory content",
      // "Not allowed to use entire song to create a music video",
    ],
    separate: [
      "Any of the above usage will require you to get in touch with our music team.",
    ],
  };

  const togglePlay = () => {
    // getting the data for the global player

    const trackInfo = {
      trackImg: trackData.imageUrl,
      trackName: trackData.name,
      trackArtistName:
        trackData.primaryArtists && trackData.primaryArtists.length > 0
          ? trackData.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "Artist Name",
      trackDuration: "03:25",
      trackCode: trackData.trackCode,
      trackUrl: getDirectLink(trackData.link),
    };
    // console.log(trackInfo, "trackinfo");

    // Show player if it's not already playing this track
    if (!isPlaying) {
      showPlayer(trackInfo);
    }

    // Toggle play/pause state
    setIsPlaying(!isPlaying);
  };

  // console.log(trackData.SKU?.[0]?.sellingPrice, "trackData");

  return (
    <div className="outer-full">
      <div className="outer-full trackDetails">
        {/* track name and details section */}
        <div className="inner mbot">
          <div className="track-artist-info">
            <div className="track-details-container">
              {/* track image */}
              <div
                className="track-image"
                style={{
                  backgroundImage:
                    trackData.imageUrl != "" && trackData.imageUrl != null
                      ? `url(${trackData.imageUrl})`
                      : `url(${randomDefaultImage("track")})`,
                }}
              >
                {/* card play button */}
                <div>
                  <CustomButton
                    isIconOnly={true}
                    backgroundColor="#fff"
                    borderRadius="50px"
                    iconColor="#000"
                    icon={isPlaying ? <FaPause /> : <FaPlay />} // using the context for managing the btn state
                    width="52px"
                    height="49px"
                    onClick={togglePlay}
                  />
                </div>
              </div>
              {/* track and artist details */}
              <div className="track-details">
                <div className="track-names">
                  <h1>{trackData.name}</h1>
                  <h2>
                    {trackData.primaryArtists &&
                    trackData.primaryArtists.length > 0
                      ? trackData.primaryArtists
                          .map((artist) => artist.artistName)
                          .join(", ")
                      : "Artist Name"}
                  </h2>
                </div>
                <div className="track-pills">
                  {
                    // console.log(trackData.displayTags)
                    trackData.displayTags && trackData.displayTags.length > 0
                      ? trackData.displayTags.split(',')
                        .map((item, index) => (
                          <div>
                             <CustomButton
                              text={item}
                              backgroundColor="rgba(49, 47, 47, 0.51)"
                              textColor="#ffffff"
                              fontFamily="Inter"
                              fontWeight="550"
                              fontSize="12px"
                            />
                        </div>
                        ))
                      : ""
                  }
                </div>
                {/* {trackData.link && (
                  <div>
                    <Waveform
                      showControls={false}
                      trackUrl={getDirectLink(trackData.link)}
                      height="40"
                      marginLeft={"0px"}
                    />
                  </div>
                )} */}
              </div>
            </div>
            {/* track like share button */}
            <div className="track-action-button">
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<FaRegHeart />}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                />
              </div>
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<FiShare2 />}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                />
              </div>
              <div>
                <CustomButton
                  isIconOnly={true}
                  backgroundColor="rgba(49, 47, 47, 0.51)"
                  borderRadius="50px"
                  iconColor="#fff"
                  icon={<BiAddToQueue />}
                  width="34px"
                  height="34px"
                  iconSize="16px"
                />
              </div>
            </div>
          </div>
        </div>
        {/* tracks about and price section */}
        <div className="inner">
          {/* track about section */}
          <div className="fl about">
            {/* about song container */}
            <div className="about-song-container">
              <h1>About The Song</h1>
              {aboutSong
                .filter(
                  (item) =>
                    item.description?.trim() || // Non-empty description
                    (item.pills &&
                      item.pills.length > 0 &&
                      item.pills[0].trim()) // Non-empty pills
                )
                .map((item, index) => (
                  <div key={index} className="about-song-item">
                    <h2>{item.title}</h2>
                    {item.description ? (
                      <h3>{item.description}</h3>
                    ) : (
                      <div className="about-song-pills-container">
                        {item.pills.map((pill, pillIndex) => (
                          <div key={pillIndex} className="about-song-pills">
                            {pill}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          {/* track pricing section */}
          <div className="fl pricing">
            {/* short form videos cards */}
            <div
              className={`pricing-container ${
                openCards[0] ? "open" : "closed"
              }`}
              onClick={() => toggleContainer(0)}
            >
              <div className="pricing-header-container">
                <div className="pricing-header">
                  <div>
                    <h1>Short Form Videos</h1>
                    <h2>For use on Instagram Reels & YouTube Shorts</h2>
                  </div>
                  <div className="pricing-header-price">
                    <span>₹</span>
                    <p>{trackData.SKU?.[0]?.sellingPrice.toLocaleString("en-IN")}</p>
                    {/* <h2>(Inclusive of all taxes)</h2> */}
                  </div>
                </div>
                <div className="pricing-header-icon">
                  <div className="arrow-container">
                    <IoIosArrowDown size={16} color="#01508F" />
                  </div>
                </div>
              </div>
              {openCards[0] && (
                <div className="pricing-details shorts-spacing ">
                  <div className="short-pros-cons-container">
                    <div className="short-pros">
                      {shortPros.pros.map((item, index) => (
                        <div key={index} className="short-pros-item">
                          <span>
                            <ImCheckmark size={14} color="#169516" />
                          </span>
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                    <div className="short-cons">
                      {shortPros.cons.map((item, index) => (
                        <div key={index} className="short-cons-item">
                          <span>
                            <ImCross size={10} color="#D45555" />
                          </span>
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <a style={{ color: "#000", opacity: "0.7" }} href="#">
                    Click here for License Terms
                  </a>
                  <div className="shorts-pros-button">
                    <Link
                      to={`https://www.figma.com/proto/792S7kopFBs31jZc1rbYaj/Hoopr%3A-Home-Page?node-id=3450-45411&node-type=frame&t=rCLjbMs2IoHCClX5-0&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=3450%3A45411`}
                    >
                      <CustomButton
                        justifyContent="space-between"
                        buyArrow={true}
                        fontSize="14px"
                        text="Buy Now"
                        backgroundColor="#C6349B"
                        textColor="#ffffff"
                        fontWeight="550"
                        width="337px"
                        height="39px"
                        borderRadius="50px"
                      />
                      <CustomButton
                        justifyContent="space-between"
                        text="Add to Cart"
                        backgroundColor="#E6E8EA"
                        textColor="#0D0D0D"
                        fontWeight="600"
                        width="337px"
                        height="39px"
                        borderRadius="50px"
                        fontSize="14px"
                        iconColor="#0D0D0D"
                        icon={<RiShoppingCart2Line />}
                      />
                    </Link>
                  </div>
                </div>
                // </div>
              )}
            </div>
            {/* buy pack and save more cards */}
            <div className="pricing-saving-band">
              <div
                className={`pricing-container ${
                  openCards[1] ? "open" : "closed"
                  }`}
                style={{ marginLeft: 0}}
                onClick={() => toggleContainer(1)}
              >
                <div className="pricing-header-container">
                  <div className="pricing-header">
                    <div>
                      <h1>Buy Pack & Save More</h1>
                      <h2>For use on Instagram Reels & YouTube Shorts</h2>
                    </div>
                    {/* <div className="pricing-header-price">
                    <span>₹</span>
                    <p>19,999</p>
                  </div> */}
                  </div>
                  <div className="pricing-header-icon">
                    <div className="arrow-container">
                      <IoIosArrowDown size={16} color="#01508F" />
                    </div>
                  </div>
                </div>
                {openCards[1] && (
                  <div className="pricing-details">
                    <PackCard
                      packCards={pricingBuyPackCards}
                      showPrice={false}
                      packType="savemoretrack"
                    />
                    <div>
                      <CustomButton
                        text="View All Packs"
                        backgroundColor="#C6349B"
                        borderRadius="50px"
                        borderColor="none"
                        width="679px"
                        height="39px"
                        textColor="#fff"
                        iconColor="#fff"
                        fontSize="15px"
                        fontFamily="Inter"
                        fontWeight="bold"
                        icon={<IoIosArrowForward />}
                        justifyContent="space-between"
                      />
                    </div>
                  </div>
                )}
              </div>
              <span>EXTRA SAVINGS</span>
              {/* <div className="pricing-saving-band">EXTRA SAVINGS</div> */}
            </div>
            {/* long form videos cards */}
            <div
              className={`pricing-container ${
                openCards[2] ? "open" : "closed"
              }`}
              onClick={() => toggleContainer(2)}
            >
              <div className="pricing-header-container">
                <div className="pricing-header">
                  <div>
                    <h1>Long Form Videos</h1>
                    <h2>
                      For use on DVCs, TVCs, Webshows etc. on YouTube, OTT, and
                      Broadcast
                    </h2>
                  </div>
                  {/* <div className="pricing-header-price">
                    <span>₹</span>
                    <p>19,999</p>
                  </div> */}
                </div>
                <div className="pricing-header-icon">
                  <div className="arrow-container">
                    <IoIosArrowDown size={16} color="#01508F" />
                  </div>
                </div>
              </div>
              {openCards[2] && (
                <div className="pricing-details">
                  <h1 className="pricing-long-form-description">
                    Ready to elevate your brand with the power of music? Share
                    your usage details with us and our team will get back to you
                    with a customized quote. Thank you.
                  </h1>
                  <div>
                    <CustomButton
                      text="Request a Quote"
                      backgroundColor="#C6349B"
                      borderRadius="50px"
                      borderColor="none"
                      width="685px"
                      height="44px"
                      textColor="#fff"
                      iconColor="#fff"
                      fontSize="15px"
                      fontFamily="Inter"
                      fontWeight="bold"
                      icon={<IoIosArrowForward />}
                      justifyContent="space-between"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* benefits section */}
            <div className="pricing-tracks-benifits">
              {tracksBenefits.map((benefit, index) => (
                <div key={index} className="pricingbenefit-container">
                  <div className="benefit-icon-container">
                    <img src={benefit.image} alt={benefit.title} />
                  </div>
                  <h1>{benefit.title}</h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* other section displayed from here and inside this div only */}
      <div className="outer-full fl trackPacks">
        {/* packs with similiar track container */}
        <div className="packs-container outer-full">
          <div className="inner">
            <h1 className="pack-contianer-heading">
              Explore Packs with this Track
            </h1>
            <div className="packs-description-view">
              <h2>Buy this track at a discounted price in these packs</h2>
              <div className="packs-viewall">
                <p>View All</p>
                <p>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </div>
            <div className="inner">
              <PackCard packCards={packCards} packType="similartrackpack" />
            </div>
          </div>
        </div>
        {/* explore similar tracks container */}
        <div className="similar-track-cards-container outer-full">
          <div className="inner">
            <h1>Explore Similar Tracks</h1>
            <div className="similar-track-cards">
              {similarTracks.map((track, index) => (
                <div key={index} className="similar-track-card">
                  <div
                    className="similar-track-card-image"
                    style={
                      {
                        //   backgroundImage: `url(${track.image})`,
                      }
                    }
                  ></div>
                  <div className="similar-track-card-text">
                    <div className="similar-track-title">
                      <h1>{track.title}</h1>
                      <h2>{track.description}</h2>
                    </div>
                    <div className="similar-track-price-container">
                      <div className="similar-track-discount-price">
                        <div className="similar-track-discount-amount">
                          <span>₹</span>
                          <p>19,999</p>
                        </div>
                        <div className="similar-track-discount-percentage">
                          50% Off
                        </div>
                      </div>
                      <div className="similar-track-cost-price">
                        <span>₹79,000</span>
                      </div>
                    </div>
                    <div className="similar-explore-track-button">
                      <div>
                        <CustomButton
                          text={track.buttonText}
                          backgroundColor="#fff"
                          borderRadius="50px"
                          borderColor="1px solid #CCCCCC"
                          width="186px"
                          height="37px"
                          textColor="#212328"
                          iconColor="#212328"
                          fontSize="16px"
                          fontFamily="Inter"
                          fontWeight="550"
                          icon={<IoIosArrowForward />}
                          justifyContent="space-between"
                        />
                      </div>
                      <div>
                        <CustomButton
                          icon={<RiShoppingCart2Line />}
                          isIconOnly={true}
                          backgroundColor="#272727"
                          iconColor="#fff"
                          borderRadius="50px"
                          height="37px"
                          width="37px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackPage;
