import config from "../config";

const subgenres = {
  "c9837522-d96c-4854-9143-1ec98e80489f": {
      "name": "Aarti",
      "description": "Devotional and spiritual music for religious ceremonies and prayers. Use Cases: Religious videos, meditation, yoga, spiritual retreats",
      "img": ""
  },
  "438ef61f-a879-4d0f-84f1-091e5f2dd4db": {
      "name": "Acoustic Indie",
      "description": "Raw and intimate music with a strong emphasis on songwriting and performance. Use Cases: Vlogs, podcasts, acoustic covers, indie films",
      "img": ""
  },
  "b296997f-a20b-4b7d-a5f2-38f31fd6d26c": {
      "name": "Acoustic Instrumentals",
      "description": "Instrumental music without vocals, perfect for creating a calming and serene atmosphere. Use Cases: Background music for videos, meditation, yoga, spa music",
      "img": ""
  },
  "fd572792-6274-40c7-b72c-9f0761f4d967": {
      "name": "Acoustic Pop",
      "description": "A blend of pop and acoustic music, creating a softer and more intimate sound. Use Cases: Romantic movies, commercials, acoustic covers",
      "img": ""
  },
  "Acoustic Rock": {
      "name": "Acoustic Rock",
      "description": "A stripped-down version of rock music, often featuring acoustic guitars and vocals. Use Cases: Indie films, documentaries, acoustic covers",
      "img": ""
  },
  "bfc7b19f-779a-46fa-b3f5-91ddf0377e5d": {
      "name": "African",
      "description": "A diverse range of music styles from Africa, including Afrobeat, Afropop, and Afro-house. Use Cases: Travel documentaries, cultural events, dance videos",
      "img": ""
  },
  "Afro Beats": {
      "name": "Afro Beats",
      "description": "A popular genre of music originating from West Africa, characterized by its rhythmic and energetic beats. Use Cases: Dance music, commercials, fitness videos",
      "img": ""
  },
  "56398c14-a155-4225-8ae0-386afaa22fd7": {
      "name": "Afro Fusion",
      "description": "A fusion of African music with other genres, such as pop, jazz, and electronic. Use Cases: Global music festivals, cultural events, world music videos",
      "img": ""
  },
  "Afro House": {
      "name": "Afro House",
      "description": "A genre of electronic dance music with African influences, often featuring deep basslines and tribal rhythms. Use Cases: Nightclubs, electronic music festivals, dance videos",
      "img": ""
  },
  "71ead0e8-928c-47e9-b2c3-a731c7f5f66c": {
      "name": "Afro Pop",
      "description": "A popular genre of music that blends African rhythms with Western pop influences. Use Cases: Music videos, commercials, radio play",
      "img": ""
  },
  "4fec4fb6-1e74-4bb2-a80e-94242b770d69": {
      "name": "Alternative",
      "description": "A broad category of music that doesn't fit into mainstream genres. Use Cases: Indie films, alternative music videos, commercials",
      "img": ""
  },
  "Asian": {
      "name": "Asian",
      "description": "A diverse range of music styles from Asia, including Chinese, Japanese, Korean, and Indian classical and contemporary music. Use Cases: Cultural documentaries, travel videos, film scores",
      "img": ""
  },
  "c064cbbb-6516-440a-b32e-52052b995dc8": {
      "name": "Bengali Folk",
      "description": "Traditional folk music from the Bengal region of India and Bangladesh. Use Cases: Regional films, cultural events, documentaries",
      "img": ""
  },
  "4bde8db6-bb0f-4d3a-aeab-3bbb8a0091ec": {
      "name": "Bhajan",
      "description": "Devotional songs in praise of Hindu deities. Use Cases: Religious ceremonies, spiritual videos, meditation music",
      "img": ""
  },
  "d2794347-a60a-43eb-8d13-d3654be65e06": {
      "name": "Bhojpuri",
      "description": "Popular folk music from the Bhojpuri region of India. Use Cases: Bhojpuri films, cultural events, regional music videos",
      "img": ""
  },
  "d4c04b08-4d53-41c1-96f5-3e913b1b508a": {
      "name": "Bihu",
      "description": "Traditional Assamese folk music from the Indian state of Assam. Use Cases: Assamese cultural events, regional films, documentaries",
      "img": ""
  },
  "Bluegrass": {
      "name": "Bluegrass",
      "description": "A genre of American roots music with a strong emphasis on string instruments. Use Cases: Country music videos, Western films, documentaries",
      "img": ""
  },
  "ed38d513-dd54-458b-b9ce-7db44388985f": {
      "name": "Bollywood",
      "description": "Popular Indian film music, often featuring romantic and danceable tunes. Use Cases: Bollywood films, Indian TV shows, commercials",
      "img": `${config.s3imgUrl}web/home/genres/bollywood.png`
  },
  "a698d423-47b7-489e-80d8-69dac7a6813e": {
  "name": "Bollywood Classic",
      "description": "A fusion of Bollywood music with other genres, such as electronic and hip-hop. Use Cases: Bollywood remixes, dance videos, party music",
      "img": ""
  },
  "4c9386b0-43b5-4e01-8d2a-766f7ec09a8e": {
      "name": "Bollywood Contemporary",
      "description": "Popular Indian film music, often featuring romantic and danceable tunes. Use Cases: Bollywood films, Indian TV shows, commercials",
      "img": `${config.s3imgUrl}web/home/genres/bollywood.jpg`
  },
  "9950bc7f-e4b2-4d85-884e-178f62e284fe": {
    "name": "Bollywood Fusion",
    "description": "",
    "img": ""
  },
  "4347a44b-a221-47c1-ba11-e81c8e46b404": {
  "name": "Bollywood Pop",
      "description": "A fusion of Bollywood music with other genres, such as electronic and hip-hop. Use Cases: Bollywood remixes, dance videos, party music",
      "img": ""
  },
  "a088e14b-8793-4cc0-93c8-b9e3447d8de2": {
    "name": "Bollywood Remix",
    "description": "",
    "img": ""
  },
  "ebb0558c-ed33-45d6-b20d-57c98efd460a": {
    "name": "Bollywood Retro",
    "description": "",
    "img": ""
  },
  "a1b541f4-e712-4f3e-ba8e-c8ad91453611": {
    "name": "Bollywood Retro",
    "description": "Bollywood Retro (60s-80s)",
    "img": ""
  },
  "787e8d29-86b8-4fff-a70b-40eba3944daf": {
      "name": "Boom Bap",
      "description": "A subgenre of hip-hop characterized by its heavy drum beats and soulful samples. Use Cases: Hip-hop music videos, rap battles, streetwear brands",
      "img": ""
  },
  "Brazilian": {
      "name": "Brazilian",
      "description": "Rhythmic and vibrant music with Brazilian influences. Use Cases: Travel vlogs, documentaries, cultural events",
      "img": ""
  },
  "Breakbeat": {
      "name": "Breakbeat",
      "description": "Fast-paced and energetic music with a strong emphasis on rhythm. Use Cases: Dance music, electronic music, video games",
      "img": ""
  },
  "Bundelkhandi": {
      "name": "Bundelkhandi",
      "description": "Traditional folk music from the Bundelkhand region of India. Use Cases: Regional documentaries, cultural events, historical dramas",
      "img": ""
  },
  "Carnatic": {
      "name": "Carnatic",
      "description": "Classical music from South India, characterized by intricate melodies and rhythms. Use Cases: Classical music concerts, cultural events, historical dramas",
      "img": ""
  },
  "Cartoon": {
      "name": "Cartoon",
      "description": "Playful and whimsical music for children's content. Use Cases: Cartoons, children's shows, educational videos",
      "img": ""
  },
  "Chants": {
      "name": "Chants",
      "description": "Spiritual and devotional music, often chanted in a rhythmic pattern. Use Cases: Religious ceremonies, meditation, yoga",
      "img": ""
  },
  "Chattisgarhi Folk": {
      "name": "Chattisgarhi Folk",
      "description": "Folk music from the Chhattisgarh region of India. Use Cases: Regional documentaries, cultural events, historical dramas",
      "img": ""
  },
  "78707e04-9869-4a1f-b82e-acc30607cea7": {
      "name": "Chill Pop",
      "description": "Relaxed and easy-listening pop music. Use Cases: Vlogs, lifestyle videos, background music for presentations",
      "img": ""
  },
  "Chiptune": {
      "name": "Chiptune",
      "description": "Electronic music created using chiptune synthesizers. Use Cases: Retro-themed video games, nostalgic content, 8-bit music",
      "img": ""
  },
  "Choir": {
      "name": "Choir",
      "description": "Vocal music performed by a choir. Use Cases: Church services, choral concerts, classical music performances",
      "img": ""
  },
  "ea1c37a8-38c6-490b-af86-6f1c0181ac04": {
      "name": "Christmas",
      "description": "",
      "img": ""
  },
  "Christmas Carol": {
      "name": "Christmas Carol",
      "description": "Festive and celebratory music for Christmas. Use Cases: Christmas carols, Christmas movies, holiday ads",
      "img": ""
  },
  "fdf06ae2-5385-487a-b631-f0f418faed2b": {
      "name": "Cinematic Electronic",
      "description": "Dramatic and cinematic electronic music. Use Cases: Movie trailers, video games, corporate presentations",
      "img": ""
  },
  "Classic Country": {
      "name": "Classic Country",
      "description": "Traditional country music with a classic sound. Use Cases: Country music videos, Western movies, documentaries",
      "img": ""
  },
  "Classic Rock": {
      "name": "Classic Rock",
      "description": "Iconic rock music from the 60s and 70s. Use Cases: Rock concerts, music documentaries, retro-themed content",
      "img": ""
  },
  "020f60e5-8ef0-4eb5-aee1-782bf5cca8a5": {
      "name": "Classical Orchestral",
      "description": "Grand and epic music performed by a symphony orchestra. Use Cases: Film scores, classical music concerts, historical documentaries",
      "img": ""
  },
  "Classical Solo": {
      "name": "Classical Solo",
      "description": "Solo performances of classical music. Use Cases: Classical music concerts, educational videos, documentaries",
      "img": ""
  },
  "Corporate Funk": {
      "name": "Corporate Funk",
      "description": "Upbeat and funky music for corporate videos and presentations. Use Cases: Corporate videos, product launches, business presentations",
      "img": ""
  },
  "Corporate Pop": {
      "name": "Corporate Pop",
      "description": "Catchy and commercial pop music for corporate videos and presentations. Use Cases: Corporate videos, product launches, business presentations",
      "img": ""
  },
  "Corporate Rap": {
      "name": "Corporate Rap",
      "description": "Energetic and rhythmic rap music for corporate videos and presentations. Use Cases: Corporate videos, product launches, business presentations",
      "img": ""
  },
  "Corporate Rock": {
      "name": "Corporate Rock",
      "description": "Energetic and powerful rock music for corporate videos and presentations. Use Cases: Corporate videos, product launches, business presentations",
      "img": ""
  },
  "Corporate Scores": {
      "name": "Corporate Scores",
      "description": "Professional and sophisticated music for corporate communications and presentations. Use Cases: Corporate videos, webinars, presentations, explainer videos",
      "img": ""
  },
  "Country Blues": {
      "name": "Country Blues",
      "description": "A blend of country and blues music, often characterized by soulful vocals and bluesy guitar riffs. Use Cases: Country music videos, documentaries about the American South, blues-inspired commercials",
      "img": ""
  },
  "1b264565-c0a8-4fa5-8a71-f6c3314e9be2": {
      "name": "Country Pop",
      "description": "A blend of country and pop music, often characterized by catchy melodies and upbeat tempos. Use Cases: Country music videos, commercials, reality TV shows",
      "img": ""
  },
  "Country Rock": {
      "name": "Country Rock",
      "description": "A blend of country and rock music, often characterized by electric guitars and powerful vocals. Use Cases: Country music videos, rock festivals, motorcycle commercials",
      "img": ""
  },
  "Cuban": {
      "name": "Cuban",
      "description": "Rhythmic and energetic music with Cuban influences. Use Cases: Latin music videos, travel documentaries, dance videos",
      "img": ""
  },
  "Dancehall": {
      "name": "Dancehall",
      "description": "A genre of Jamaican dance music that is characterized by its fast tempo and rhythmic basslines. Use Cases: Dancehall music videos, club music, party playlists",
      "img": ""
  },
  "dabdc9ac-c4c3-49bc-83ac-272b8ea347c2": {
      "name": "Deep House",
      "description": "A subgenre of house music characterized by its deep basslines and soulful vocals. Use Cases: Electronic music festivals, chill-out lounges, yoga and meditation",
      "img": ""
  },
  "Disco": {
      "name": "Disco",
      "description": "Energetic and upbeat music from the 1970s. Use Cases: Disco-themed parties, retro commercials, dance videos",
      "img": ""
  },
  "Disco-Funk": {
      "name": "Disco-Funk",
      "description": "A fusion of disco and funk music, characterized by its danceable rhythms and soulful vocals. Use Cases: Disco-themed parties, retro commercials, dance videos",
      "img": ""
  },
  "Downtempo": {
      "name": "Downtempo",
      "description": "Slow-tempo electronic music, often used to create a relaxing and meditative atmosphere. Use Cases: Yoga videos, meditation apps, spa commercials",
      "img": ""
  },
  "07a5bbab-1344-424a-a69e-ba82d179913f": {
      "name": "Drill",
      "description": "A subgenre of hip-hop characterized by its dark and aggressive sound. Use Cases: Hip-hop music videos, urban culture content, gaming videos",
      "img": ""
  },
  "Drones": {
      "name": "Drones",
      "description": "Atmospheric and ambient music, often used to create a sense of space and mystery. Use Cases: Science fiction films, video game soundtracks, meditation music",
      "img": ""
  },
  "bf711d66-4419-4302-b839-5962344e91f7": {
      "name": "Drum And Bass",
      "description": "Fast-paced electronic music characterized by heavy basslines and complex rhythms. Use Cases: Electronic music festivals, raves, gaming videos",
      "img": ""
  },
  "Dubstep": {
      "name": "Dubstep",
      "description": "A genre of electronic dance music characterized by its heavy basslines and distorted sounds. Use Cases: Electronic music festivals, raves, gaming videos",
      "img": ""
  },
  "021bfb6a-c491-46ec-bd92-61839acde38d": {
      "name": "EDM",
      "description": "A broad genre of electronic dance music, encompassing a wide range of subgenres. Use Cases: Electronic music festivals, raves, dance videos",
      "img": ""
  },
  "Electro-Funk": {
      "name": "Electro-Funk",
      "description": "A fusion of electronic and funk music, characterized by its funky basslines and electronic beats. Use Cases: Dance music videos, commercials, retro-themed content",
      "img": ""
  },
  "33965226-c3e7-4a79-af02-989a9722045b": {
      "name": "Electronic Fusion",
      "description": "A blend of electronic music with other genres, such as classical, jazz, or world music. Use Cases: Experimental music videos, art installations, avant-garde performances",
      "img": ""
  },
  "1e533591-9d2d-4678-9346-ba12b7c4d8a4": {
      "name": "Epic Cinematic",
      "description": "Dramatic and cinematic music, often used for trailers, movies, and TV shows. Use Cases: Movie trailers, video game soundtracks, corporate videos",
      "img": ""
  },
  "dd2b5b23-17ad-4e6e-a954-1607baa1a926": {
      "name": "European",
      "description": "A wide range of music styles from Europe, including classical, folk, and pop. Use Cases: Historical documentaries, travel videos, cultural events",
      "img": ""
  },
  "b94637da-3f17-4788-8ac4-65150ad8a69d": {
      "name": "Experimental",
      "description": "Innovative and avant-garde music that pushes the boundaries of traditional genres. Use Cases: Art installations, experimental films, avant-garde performances",
      "img": ""
  },
  "Experimental Jazz": {
      "name": "Experimental Jazz",
      "description": "Innovative and avant-garde jazz music that pushes boundaries. Use Cases: Art house films, experimental documentaries, avant-garde theater",
      "img": ""
  },
  "Experimental Rock": {
      "name": "Experimental Rock",
      "description": "A genre-bending fusion of rock music with experimental elements. Use Cases: Indie films, alternative music videos, experimental theater",
      "img": ""
  },
  "Film Music": {
      "name": "Film Music",
      "description": "Dramatic and evocative music composed for film and television. Use Cases: Movie trailers, TV series, video game soundtracks",
      "img": ""
  },
  "37435f36-8d5e-4cea-9ad8-83e5699a8ab8": {
      "name": "Filmi",
      "description": "Popular Bollywood music, including songs from movies and albums. Use Cases: Bollywood movies, TV shows, Indian weddings, parties",
      "img": ""
  },
  "Flamenco": {
      "name": "Flamenco",
      "description": "Passionate and rhythmic Spanish music with strong guitar work and vocals. Use Cases: Flamenco dance performances, Spanish-themed content, world music compilations",
      "img": ""
  },
  "Funk Rock": {
      "name": "Funk Rock",
      "description": "A fusion of funk and rock music, characterized by groovy rhythms and energetic guitar riffs. Use Cases: Music videos, commercials, dance-oriented content",
      "img": ""
  },
  "Fusion": {
      "name": "Fusion",
      "description": "A blend of different musical styles and genres. Use Cases: World music compilations, cultural events, fusion cuisine videos",
      "img": ""
  },
  "80ff15dd-59c9-4a92-aa3c-164e34d01de3": {
      "name": "Future Bass",
      "description": "A subgenre of electronic music characterized by heavy basslines and future-oriented sounds. Use Cases: Electronic music videos, club music, gaming",
      "img": ""
  },
  "Future Garage": {
      "name": "Future Garage",
      "description": "A genre of electronic music that combines elements of garage, house, and hip-hop. Use Cases: Electronic music videos, club music, video games",
      "img": ""
  },
  "b5e7c944-ab5f-4533-a1f1-059b4235b5e1": {
      "name": "Gangster Hip Hop",
      "description": "A subgenre of hip hop that focuses on themes of crime, violence, and street life. Use Cases: Music videos, gangster films, urban culture content",
      "img": ""
  },
  "6b34a905-189f-4532-9935-2cd9073a2cd3": {
      "name": "Garba - Dandiya",
      "description": "Energetic and festive music for the Indian festival of Navratri. Use Cases: Navratri celebrations, Indian festivals, cultural events",
      "img": ""
  },
  "Ghazals": {
      "name": "Ghazals",
      "description": "Poetic and romantic music from the Indian subcontinent. Use Cases: Romantic films, poetry readings, cultural events",
      "img": ""
  },
  "Goan": {
      "name": "Goan",
      "description": "Energetic and rhythmic music from the Indian state of Goa. Use Cases: Beach parties, travel vlogs, cultural events",
      "img": ""
  },
  "Gospel": {
      "name": "Gospel",
      "description": "Spiritual and uplifting music with strong religious themes. Use Cases: Church services, religious ceremonies, inspirational videos",
      "img": ""
  },
  "94461caa-c25e-4359-b10e-cd9c4744e9a2": {
      "name": "Gujarati",
      "description": "",
      "img": ""
  },
  "Hard Rock": {
      "name": "Hard Rock",
      "description": "Intense and aggressive rock music with heavy guitar riffs and powerful vocals. Use Cases: Rock concerts, music videos, rock documentaries",
      "img": ""
  },
  "f5465575-d614-419f-afd7-92d3fa3eed95": {
      "name": "Haryanvi",
      "description": "Folk music from the Indian state of Haryana. Use Cases: Haryanvi cultural events, regional films, documentaries",
      "img": ""
  },
  "7d389cb0-0323-45f3-959f-03b67db27367": {
      "name": "Haryanvi Folk",
      "description": "Traditional folk music from Haryana, often featuring energetic dance and music. Use Cases: Folk music compilations, cultural events, regional documentaries",
      "img": ""
  },
  "Himachali": {
      "name": "Himachali",
      "description": "Folk music from the Indian state of Himachal Pradesh. Use Cases: Regional festivals, cultural events, travel documentaries",
      "img": ""
  },
  "62b3ca6a-5b09-4665-bc40-1e14de47032c": {
      "name": "Hindi Folk",
      "description": "Traditional folk music from various regions of India. Use Cases: Folk music compilations, cultural events, regional documentaries",
      "img": ""
  },
  "9a317b5a-4f2f-40ab-b901-c08ce0085624": {
      "name": "House",
      "description": "Energetic and danceable electronic music with a strong emphasis on rhythm and beat. Use Cases: Nightclubs, electronic music festivals, dance videos",
      "img": ""
  },
  "Hybrid Trap": {
      "name": "Hybrid Trap",
      "description": "A fusion of Indian classical and electronic music, perfect for creating unique and edgy soundscapes. Use Cases: Music videos, short films, gaming, commercials.",
      "img": ""
  },
  "21af4826-531e-489b-8452-fe240b53f905": {
      "name": "Indian Ambient",
      "description": "Ethereal and atmospheric music, ideal for creating a calming and meditative mood. Use Cases: Yoga videos, meditation apps, spa commercials, background music for corporate videos.",
      "img": ""
  },
  "784e37ec-9557-45e1-9358-a17d29faab94": {
      "name": "Indian Background Music",
      "description": "Versatile background music for various content types, including documentaries, corporate videos, and vlogs. Use Cases: Documentaries, corporate videos, explainer videos, vlogs, podcasts.",
      "img": ""
  },
  "460f1eb1-5466-4e76-9529-1ebbdc11a21d": {
      "name": "Indian Classical Fusion",
      "description": "A blend of traditional Indian classical music with contemporary genres. Use Cases: Cultural events, historical documentaries, fusion music videos.",
      "img": ""
  },
  "492f7c6a-eb1c-4fb9-9d45-73070cea46f7": {
      "name": "Indian Classical Instrumental",
      "description": "Pure instrumental Indian classical music, perfect for meditation, yoga, and classical performances. Use Cases: Meditation apps, yoga videos, classical music concerts, historical documentaries.",
      "img": ""
  },
  "9ffb292f-fe2a-412c-821a-54513d194d05": {
      "name": "Indian Classical Vocal",
      "description": "Traditional Indian classical vocal music, ideal for devotional and cultural content. Use Cases: Religious ceremonies, cultural events, devotional music videos.",
      "img": ""
  },
  "e64a6921-5aa5-49b9-a646-eb9261bb6852": {
      "name": "Indian Folk Fusion",
      "description": "A fusion of traditional Indian folk music with contemporary genres. Use Cases: Folk music videos, cultural events, regional tourism promotions.",
      "img": ""
  },
  "Indian Funk": {
      "name": "Indian Funk",
      "description": "Groovy and funky music with Indian influences. Use Cases: Dance videos, commercials, retro-themed content.",
      "img": ""
  },
  "34b005a0-9e08-482e-b5a6-b89a27d3de2d": {
      "name": "Indian Hip Hop",
      "description": "Energetic and rhythmic hip-hop music with Indian lyrics and beats. Use Cases: Music videos, commercials, gaming, streetwear brands.",
      "img": `${config.s3imgUrl}web/home/genres/hiphop.jpg`
  },
  "87473198-dfb8-4640-82da-b487d48c0d26": {
      "name": "Indian Orchestral",
      "description": "Grand and epic orchestral music with Indian influences. Use Cases: Film scores, historical dramas, epic storytelling.",
      "img": ""
  },
  "571b953a-5198-45c8-8c02-2ca08f60f262": {
      "name": "Indian Pop",
      "description": "Catchy and commercial pop music with Indian influences. Use Cases: Pop music videos, commercials, radio jingles.",
      "img": `${config.s3imgUrl}web/home/genres/indianpop.jpg`
  },
  "Indian Rhythms": {
      "name": "Indian Rhythms",
      "description": "Rhythmic and percussive music from India. Use Cases: Dance videos, fitness videos, cultural events.",
      "img": ""
  },
  "9cc439ea-cefa-4ffe-a1a9-1b55bcf30424": {
      "name": "Indian Rock",
      "description": "Energetic and rebellious rock music with Indian influences. Use Cases: Rock concerts, music festivals, rock documentaries.",
      "img": ""
  },
  "c426e39e-c4d8-4787-adbe-f0cf57fc06e4": {
      "name": "Indie",
      "description": "Independent and alternative music with a focus on originality and creativity. Use Cases: Indie films, music videos, short films.",
      "img": ""
  },
  "e4f11e1e-b8e7-45a8-8b12-d5f059cabd31": {
      "name": "Indie Pop",
      "description": "A blend of indie and pop music, often with a focus on songwriting and melody. Use Cases: Indie music festivals, music blogs, social media.",
      "img": ""
  },
  "Indie Rock": {
      "name": "Indie Rock",
      "description": "A blend of indie and rock music, often with a focus on guitars and drums. Use Cases: Indie rock concerts, music festivals, alternative music blogs.",
      "img": ""
  },
  "Jazz Funk": {
      "name": "Jazz Funk",
      "description": "A fusion of jazz and funk music, combining improvisation and groove. Use Cases: Jazz clubs, music festivals, commercials.",
      "img": ""
  },
  "K-Pop": {
      "name": "K-Pop",
      "description": "Energetic and catchy pop music from South Korea. Use Cases: K-pop fan videos, dance covers, social media.",
      "img": ""
  },
  "Kashmiri Folk": {
      "name": "Kashmiri Folk",
      "description": "Traditional folk music from Kashmir. Use Cases: Cultural documentaries, regional tourism, traditional music videos.",
      "img": ""
  },
  "Kids Song": {
      "name": "Kids Song",
      "description": "Fun and playful music for children. Use Cases: Children's shows, educational videos, toys and games.",
      "img": ""
  },
  "Koli": {
      "name": "Koli",
      "description": "Energetic and rhythmic folk music from Maharashtra. Use Cases: Marathi films, cultural events, dance performances",
      "img": ""
  },
  "Latin Jazz": {
      "name": "Latin Jazz",
      "description": "A fusion of Latin rhythms and jazz improvisation. Use Cases: Latin-inspired music videos, lounge bars, cocktail parties",
      "img": ""
  },
  "dd9ba51e-48e8-4c40-8415-3e1cbe63bb2a": {
      "name": "Latin Pop",
      "description": "Romantic and upbeat music with Latin American influences. Use Cases: Latin-themed content, travel vlogs, dance videos",
      "img": ""
  },
  "Latin Rock": {
      "name": "Latin Rock",
      "description": "Energetic and rebellious music with Latin American influences. Use Cases: Rock concerts, music festivals, rock documentaries",
      "img": ""
  },
  "Lavani": {
      "name": "Lavani",
      "description": "Traditional Marathi folk dance and music. Use Cases: Marathi cultural events, dance performances, historical dramas",
      "img": ""
  },
  "88252cfc-43e0-42b1-a1d8-615a28291ff5": {
      "name": "Lo-fi - Hip Hop",
      "description": "Laid-back and mellow hip-hop beats with a nostalgic feel. Use Cases: Chillhop videos, study sessions, background music for vlogs",
      "img": ""
  },
  "4788870c-8a9c-4fd7-adb2-e32e97d12f07": {
      "name": "Lo-Fi Electronic",
      "description": "Chill and mellow electronic music with a nostalgic feel. Use Cases: Study sessions, relaxation videos, background music for vlogs",
      "img": ""
  },
  "Lullaby": {
      "name": "Lullaby",
      "description": "Gentle and soothing music for babies and children. Use Cases: Children's videos, bedtime stories, baby products",
      "img": ""
  },
  "324c7874-bcc5-4fd5-92bb-5d4e0a567212": {
      "name": "Marathi",
      "description": "A diverse range of music from the Marathi language and culture. Use Cases: Marathi films, TV shows, cultural events",
      "img": ""
  },
  "Metal": {
      "name": "Metal",
      "description": "Heavy and intense music with distorted guitars and powerful vocals. Use Cases: Metal concerts, music videos, gaming",
      "img": ""
  },
  "Middle Eastern": {
      "name": "Middle Eastern",
      "description": "Exotic and rhythmic music from the Middle East. Use Cases: Travel documentaries, cultural events, Middle Eastern cuisine videos",
      "img": ""
  },
  "Modern Blues": {
      "name": "Modern Blues",
      "description": "Contemporary blues music with a modern twist. Use Cases: Blues clubs, music festivals, blues-themed content",
      "img": ""
  },
  "6d31f113-2a13-4686-a0b2-6dd74da80643": {
      "name": "Modern Hip Hop",
      "description": "Contemporary hip-hop music with a focus on lyrics and beats. Use Cases: Music videos, commercials, gaming, streetwear brands",
      "img": ""
  },
  "c709058b-8c13-43f7-a561-e176128f10e9": {
      "name": "Modern Pop",
      "description": "Catchy and commercial pop music with a modern sound. Use Cases: Pop music videos, commercials, radio jingles",
      "img": ""
  },
  "Motown": {
      "name": "Motown",
      "description": "Soulful and rhythmic music from the Motown era. Use Cases: Soul music documentaries, retro-themed content, vintage commercials",
      "img": ""
  },
  "3906cc2b-3902-411e-b510-37cf5120e890": {
      "name": "Neo Soul",
      "description": "A blend of soul, jazz, and R&B. Use Cases: Soul music documentaries, neo-soul concerts, music videos",
      "img": ""
  },
  "ae6d9876-4dbd-48e4-8387-5994025d225e": {
      "name": "Nursery Rhymes",
      "description": "Simple and catchy songs for children. Use Cases: Children's shows, educational videos, toys and games",
      "img": ""
  },
  "Odissi": {
      "name": "Odissi",
      "description": "Classical Indian dance and music form from Odisha. Use Cases: Cultural events, dance performances, historical dramas",
      "img": ""
  },
  "21be04de-209a-4e7d-84b0-c553ce7272b3": {
      "name": "Old School Hip Hop",
      "description": "Classic hip-hop music from the 1980s and 1990s. Use Cases: Hip-hop documentaries, nostalgic content, retro commercials",
      "img": ""
  },
  "Old School Jazz": {
      "name": "Old School Jazz",
      "description": "Traditional jazz music from the early 20th century. Use Cases: Jazz clubs, historical documentaries, vintage-themed content",
      "img": ""
  },
  "Opera": {
      "name": "Opera",
      "description": "Dramatic and emotional music, often performed in theaters. Use Cases: Historical dramas, opera performances, classical music concerts",
      "img": ""
  },
  "52fd333d-40e1-40e4-888f-07715763596d": {
      "name": "Orchestral",
      "description": "Grand and powerful music performed by an orchestra. Use Cases: Film scores, TV shows, commercials, video games",
      "img": ""
  },
  "Orchestral Pop": {
      "name": "Orchestral Pop",
      "description": "A blend of classical orchestral music and modern pop elements. Use Cases: Pop music videos, commercials, film scores",
      "img": ""
  },
  "3c77eaab-be10-495e-9e55-0b2845a37d35": {
      "name": "Pahadi Folk",
      "description": "Traditional folk music from the Himalayan regions of India. Use Cases: Documentaries, cultural events, travel videos",
      "img": ""
  },
  "a3f591b3-e625-4292-bd1a-31db7419531e": {
      "name": "Patriotic",
      "description": "Inspiring and uplifting music that evokes a sense of nationalism. Use Cases: Patriotic videos, documentaries, national events",
      "img": ""
  },
  "Pop Rock": {
      "name": "Pop Rock",
      "description": "A blend of pop and rock music, often characterized by catchy melodies and energetic rhythms. Use Cases: Pop music videos, commercials, TV shows",
      "img": ""
  },
  "Post / Ambient Rock": {
      "name": "Post / Ambient Rock",
      "description": "Experimental and atmospheric rock music with a focus on texture and mood. Use Cases: Indie films, art house movies, experimental music videos",
      "img": ""
  },
  "Psychedelic Rock": {
      "name": "Psychedelic Rock",
      "description": "Psychedelic and experimental rock music with a focus on mind-altering experiences. Use Cases: Music videos, documentaries about psychedelic culture, experimental films",
      "img": ""
  },
  "7e7c6d9c-c519-47ce-a441-c0aa8a1f9a2f": {
      "name": "Punjabi",
      "description": "Energetic and rhythmic music from the Punjab region of India. Use Cases: Punjabi music videos, Bollywood films, cultural events",
      "img": ""
  },
  "0a01fe4c-f4ea-4259-b60f-2276bde0c47e": {
      "name": "Punjabi Folk",
      "description": "Traditional folk music from the Punjab region of India. Use Cases: Folk music videos, cultural events, documentaries",
      "img": ""
  },
  "Punk": {
      "name": "Punk",
      "description": "Loud and rebellious music with a raw and energetic sound. Use Cases: Music videos, documentaries about punk culture, punk rock concerts",
      "img": ""
  },
  "fa21f002-a757-4be3-a767-2e175496d080": {
      "name": "Rajasthani Folk",
      "description": "Colorful and vibrant folk music from the Rajasthan region of India. Use Cases: Cultural events, travel documentaries, Bollywood films",
      "img": ""
  },
  "0a32b88a-c417-4658-bfa8-377eb3a55d89": {
      "name": "Reggae",
      "description": "Laid-back and rhythmic music with Jamaican roots. Use Cases: Beach and vacation videos, travel documentaries, reggae-themed content",
      "img": ""
  },
  "Reggaeton": {
      "name": "Reggaeton",
      "description": "Energetic and rhythmic Latin music with Caribbean influences. Use Cases: Dance videos, music videos, commercials, Latin-themed content",
      "img": ""
  },
  "Retro Funk": {
      "name": "Retro Funk",
      "description": "A revival of the funk music genre, often with a modern twist. Use Cases: Retro-themed commercials, music videos, dance videos",
      "img": ""
  },
  "2edc30aa-6f89-4d59-88c3-c801c0e8685e": {
      "name": "Retro Pop",
      "description": "Nostalgic pop music from past decades. Use Cases: Vintage-themed content, retro commercials, nostalgia-based campaigns",
      "img": ""
  },
  "Retro Rock": {
      "name": "Retro Rock",
      "description": "Classic rock music from the 60s and 70s. Use Cases: Rock documentaries, music videos, retro-themed content",
      "img": ""
  },
  "Retrowave": {
      "name": "Retrowave",
      "description": "A genre of electronic music inspired by 1980s synthwave and retrofuturism. Use Cases: Retro-themed videos, video games, science fiction movies",
      "img": ""
  },
  "Rock And Roll": {
      "name": "Rock And Roll",
      "description": "Energetic and rebellious music with a focus on guitars and drums. Use Cases: Rock concerts, music videos, rock documentaries",
      "img": ""
  },
  "Rumba": {
      "name": "Rumba",
      "description": "A lively and rhythmic Latin dance music. Use Cases: Dance videos, Latin-themed content, cultural events",
      "img": ""
  },
  "Salsa": {
      "name": "Salsa",
      "description": "Energetic and rhythmic Latin music, perfect for dance routines, Latin-themed events, and travel vlogs.",
      "img": ""
  },
  "Samba": {
      "name": "Samba",
      "description": "Lively and upbeat Brazilian music, ideal for carnival celebrations, Brazilian-themed content, and dance performances.",
      "img": ""
  },
  "Scores": {
      "name": "Scores",
      "description": "Dramatic and cinematic music for film, TV, and video game soundtracks.",
      "img": ""
  },
  "Smooth Funk": {
      "name": "Smooth Funk",
      "description": "A blend of funk and soul, perfect for creating a laid-back and groovy atmosphere.",
      "img": ""
  },
  "Smooth Jazz": {
      "name": "Smooth Jazz",
      "description": "Sophisticated and mellow jazz music, ideal for background music in restaurants, lounges, and spas.",
      "img": ""
  },
  "Soft House": {
      "name": "Soft House",
      "description": "Melodic and dreamy house music, perfect for creating a chilled-out and atmospheric vibe.",
      "img": ""
  },
  "Soft Rock": {
      "name": "Soft Rock",
      "description": "Melodic and romantic rock music, perfect for love songs, romantic comedies, and sentimental moments.",
      "img": ""
  },
  "Soul": {
      "name": "Soul",
      "description": "Soulful and expressive music with a strong emphasis on emotion. Use Cases: Documentaries, historical dramas, blues-themed content",
      "img": ""
  },
  "90a694e2-64b1-4d40-ad53-37c67b1fbffe": {
      "name": "Soundscapes",
      "description": "Ambient and atmospheric music to create mood and atmosphere. Use Cases: Meditation videos, yoga videos, nature documentaries",
      "img": ""
  },
  "892d7556-d816-4b50-8046-cc6e260deba4": {
      "name": "South Indian Folk",
      "description": "Traditional folk music from the southern states of India. Use Cases: Regional festivals, cultural documentaries, traditional dance performances",
      "img": ""
  },
  "Sri Lankan": {
      "name": "Sri Lankan",
      "description": "Vibrant and rhythmic music from Sri Lanka. Use Cases: Travel documentaries, cultural events, Bollywood films",
      "img": ""
  },
  "String Quartet": {
      "name": "String Quartet",
      "description": "Classical music played by a string quartet, perfect for elegant and sophisticated events. Use Cases: Weddings, corporate events, historical dramas",
      "img": ""
  },
  "1c00f516-cf24-4e1d-9f7c-3047e221d631": {
      "name": "Sufi",
      "description": "Mystical and devotional music with a focus on love and spirituality. Use Cases: Sufi music concerts, religious ceremonies, spiritual retreats",
      "img": ""
  },
  "Sufi Rock": {
      "name": "Sufi Rock",
      "description": "A fusion of Sufi and rock music, creating a unique and energetic sound. Use Cases: Music festivals, cultural events, fusion music videos",
      "img": ""
  },
  "494e7647-f848-4799-9614-4247cb5f875a": {
      "name": "Swing",
      "description": "Upbeat and jazzy music with a swinging rhythm. Use Cases: Jazz clubs, vintage-themed events, dance performances",
      "img": ""
  },
  "beb67b82-32fa-41f3-942d-b6ced475a38b": {
      "name": "Synth-Pop",
      "description": "Electronic music with a pop sensibility, often featuring catchy melodies and synth sounds. Use Cases: Music videos, commercials, video games",
      "img": ""
  },
  "Synthwave": {
      "name": "Synthwave",
      "description": "Retro-inspired electronic music with a focus on nostalgia and 80s aesthetics. Use Cases: Retro-themed commercials, video games, synthwave music videos",
      "img": ""
  },
  "Tango": {
      "name": "Tango",
      "description": "Passionate and dramatic music from Argentina. Use Cases: Tango performances, Latin-themed events, romantic movies",
      "img": ""
  },
  "Tech House": {
      "name": "Tech House",
      "description": "Energetic and rhythmic electronic music with a focus on deep basslines and hypnotic grooves. Use Cases: Nightclubs, to depict raves scenarios, electronic music festivals",
      "img": ""
  },
  "f04cbc76-49bb-469b-9025-e0d403bc1fa7": {
      "name": "Techno",
      "description": "Minimalistic and repetitive electronic music, perfect for creating a hypnotic and immersive atmosphere. Use Cases: Techno clubs, raves, electronic music festivals",
      "img": ""
  },
  "Textures": {
      "name": "Textures",
      "description": "Experimental and atmospheric music, often incorporating unconventional sounds and textures. Use Cases: Abstract films, experimental art installations, avant-garde music videos",
      "img": ""
  },
  "Traditional Blues": {
      "name": "Traditional Blues",
      "description": "A soulful and melancholic genre with a strong emphasis on improvisation. Use Cases: Documentaries, historical dramas, blues-themed content",
      "img": ""
  },
  "e7aaabe9-0829-49c3-b40e-2f07afe7a0ed": {
      "name": "Trance",
      "description": "Psychedelic and repetitive electronic music designed to induce a trance-like state. Use Cases: Raves, electronic music festivals, dance videos",
      "img": ""
  },
  "3e011b3a-58c9-419a-9e28-9d174379bc66": {
      "name": "Trap",
      "description": "A subgenre of hip-hop characterized by heavy bass, hi-hat patterns, and a dark, atmospheric mood. Use Cases: Rap music videos, gaming, urban culture content",
      "img": ""
  },
  "Urdu Folk": {
      "name": "Urdu Folk",
      "description": "A traditional folk music genre from the Indian subcontinent, often featuring soulful vocals and intricate instrumentation. Use Cases: Cultural documentaries, historical dramas, Bollywood films",
      "img": ""
  },
  "Video Games Music": {
      "name": "Video Games Music",
      "description": "Dynamic and immersive music designed to enhance the gaming experience. Use Cases: Video games, gaming trailers, gaming streams",
      "img": ""
  },
  "a51ead99-5d5b-438e-8d20-fe9ccee7ff26": {
      "name": "Western Classical Vocal",
      "description": "Classical vocal music from the Western world, often accompanied by instruments like piano, violin, and cello. Use Cases: Classical music concerts, historical dramas, romantic films",
      "img": ""
  }
};

export default subgenres;
