import React, { useState, useEffect } from "react";
import styles from "../styles/Cart.module.css";
import { IoIosClose, IoIosArrowUp } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomImg from "../components/CustomImg/CustomImg";
import { FiMinus, FiPlus } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
} from "../utils/redux/cartSlice";
import { useNavigate } from "react-router-dom";

const Cart = ({ isOpen, onClose, cardData }) => {
  const [isCartOpen, setIsCartOpen] = useState(isOpen); // Sync with parent initially
  // const [cartItems, setCartItems] = useState([]); // Manage all cart items
  const cartItems = useSelector((state) => state.cart.items); // Access cart items from Redux store
  const dispatch = useDispatch(); // Hook to dispatch actions
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Sync `isCartOpen` with `isOpen` prop whenever it changes
  useEffect(() => {
    setIsCartOpen(isOpen);
  }, [isOpen]);

  // Update cartItems when cardData changes
  // useEffect(() => {
  //   if (cardData && Object.keys(cardData).length > 0) {
  //     setCartItems((prevItems) => {
  //       const itemIndex = prevItems.findIndex(
  //         (item) =>
  //           item.code === cardData.code && item.cardType === cardData.cardType
  //       );

  //       if (itemIndex !== -1) {
  //         // Item exists, increment quantity only once
  //         return prevItems.map((item, index) =>
  //           index === itemIndex
  //             ? { ...item, quantity: item.quantity + 1 }
  //             : item
  //         );
  //       } else {
  //         // Item doesn't exist, add it with quantity 1
  //         return [...prevItems, { ...cardData, quantity: 1 }];
  //       }
  //     });
  //   }
  // }, [cardData]);

  // Function to close the modal
  const closeModal = () => {
    setIsCartOpen(false); // Close modal locally
    onClose(); // Notify the parent to close the modal
  };

  if (!isCartOpen) return null; // Don't render if the modal is closed

  // console.log(cartItems, "cardData");

  // // Increment quantity
  // const handleIncrement = (code) => {
  //   setCartItems((prevItems) =>
  //     prevItems.map((item) =>
  //       item.code === code
  //         ? { ...item, quantity: (item.quantity || 1) + 1 }
  //         : item
  //     )
  //   );
  // };

  // // Decrement quantity
  // const handleDecrement = (code) => {
  //   setCartItems((prevItems) =>
  //     prevItems.map((item) =>
  //       item.code === code && (item.quantity || 1) > 1
  //         ? { ...item, quantity: (item.quantity || 1) - 1 }
  //         : item
  //     )
  //   );
  // };

  // // Remove item from cart
  // const handleRemoveItem = (code) => {
  //   setCartItems((prevItems) => prevItems.filter((item) => item.code !== code));
  // };

  const handleIncrement = (code) => {
    dispatch(incrementQuantity(code)); // Increment quantity in Redux
  };

  const handleDecrement = (code) => {
    dispatch(decrementQuantity(code)); // Decrement quantity in Redux
  };

  const handleRemoveItem = (code) => {
    dispatch(removeFromCart(code)); // Remove item from Redux cart
  };

  const handleCheckout = () => {
    closeModal(); // Close the modal
    // setTimeout(() => {
    navigate("/checkout"); // Navigate after modal closes
    // }, 300); // Add a small delay for modal animation if necessary
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        {/* cart header section */}
        <div className={styles.header}>
          <div className={styles.heading}>
            <p className={styles.headingtext}>My Cart</p>
            <div className={styles.cartCount}>{cartItems.length}</div>
          </div>
          <IoIosClose
            onClick={closeModal}
            color="#000"
            cursor="pointer"
            size={34}
          />
        </div>
        {/* billing cards */}
        <div className={styles.billItems}>
          {cartItems.length > 0 ? (
            cartItems.map((item, index) => (
              <div key={index} className={styles.cardContainer}>
                <div className={styles.cardDetails}>
                  <CustomImg
                    type={item.cardType}
                    id={item.code}
                    alt={item.preText}
                    imgClass={styles.trackImage}
                    src={item.cardImg}
                  />
                  <div className={styles.cardInfo}>
                    <p className={styles.cardPriText}>{item.preText}</p>
                    <p className={styles.cardSectext}>{item.secText}</p>
                    <div className={styles.itemControls}>
                      <div className={styles.itemIncBtn}>
                        <FiMinus
                          size={14}
                          color="#000"
                          cursor="pointer"
                          onClick={() => handleDecrement(item.code)}
                        />
                        <span className={styles.itemCount}>
                          {item.quantity || 1}
                        </span>
                        <FiPlus
                          size={14}
                          color="#000"
                          cursor="pointer"
                          onClick={() => handleIncrement(item.code)}
                        />
                      </div>
                      <AiOutlineDelete
                        onClick={() => handleRemoveItem(item.code)}
                        size={22}
                        // color="#3f3f3f"
                        cursor="pointer"
                        className={
                          styles.deleteIcon
                        } /* Apply the new CSS class */
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.itemTotalPrice}>
                  <span className={styles.cardTotalPriceText}>
                    Pay (Inc. GST)
                  </span>
                  {/* <p className={styles.cardTotalPrice}>
                    ₹
                    {(
                      parseInt(item.sp.replace(",", "")) * item.quantity
                    ).toLocaleString("en-IN")}
                  </p> */}
                  <p className={styles.cardTotalPrice}>₹{item.sp}</p>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.emptyCartMessage}>
              <p>Your cart is empty.</p>
              <p>Add items to your cart to see them here!</p>
            </div>
          )}
        </div>
        {/* total bill summary section */}
        <div className={styles.billSummary}>
          <p className={styles.sumHeadingText}>Billing Summary</p>
          {/* <IoIosArrowUp onClick={() => !isOpen} size={24} color="#fff" /> */}
        </div>
        <div className={styles.billInfo}>
          <div className={styles.billDetail}>
            <div className={styles.detailHeading}>
              <p>Subtotal</p>
            </div>
            {/* displaying the cost price here */}
            <div className={styles.price}>
              <span>
                ₹
                {Number(
                  (
                    cartItems.reduce(
                      (subtotal, item) =>
                        subtotal +
                        parseInt(item.sp.replace(",", ""), 10) *
                          (item.quantity || 1),
                      0
                    ) / 1.18
                  ).toFixed(2) // Ensure two decimal places for precision
                ).toLocaleString("en-IN")}
                {/* ₹
                {cartItems
                  .reduce(
                    (subtotal, item) =>
                      subtotal +
                      parseInt(item.cp.replace(",", ""), 10) *
                        (item.quantity || 1),
                    0
                  )
                  .toLocaleString("en-IN")} */}
              </span>
            </div>
          </div>
          {/* <div className={styles.billDetail}>
            <div className={styles.detailHeading}>
              <p>Discount</p>
            </div>
            <div
              style={{ color: "#00B049", fontWeight: 550 }}
              className={styles.price}
            >
              <span>
                -
                {cartItems
                  .reduce(
                    (totalSavings, item) =>
                      totalSavings +
                      (parseInt(item.cp.replace(",", "")) -
                        parseInt(item.sp.replace(",", ""))) *
                        item.quantity,
                    0
                  )
                  .toLocaleString("en-IN")}
              </span>
            </div>
          </div> */}
          <div className={styles.billDetail}>
            <div className={styles.detailHeading}>
              <p>TAX (18%GST)</p>
            </div>
            <div className={styles.price}>
              <span>
                {" "}
                ₹
                {Number(
                  (
                    cartItems.reduce(
                      (total, item) =>
                        total +
                        parseInt(item.sp.replace(",", ""), 10) * item.quantity,
                      0
                    ) -
                    cartItems.reduce(
                      (subtotal, item) =>
                        subtotal +
                        (parseInt(item.sp.replace(",", ""), 10) *
                          (item.quantity || 1)) /
                          1.18,
                      0
                    )
                  ).toFixed(2) // Ensure two decimal places for precision
                ).toLocaleString("en-IN")}
              </span>
            </div>
          </div>
          <div className={styles.billDetail}>
            <div
              style={{ fontWeight: "bold" }}
              className={styles.detailHeading}
            >
              <p>Total</p>
            </div>
            <div style={{ fontWeight: "bold" }} className={styles.price}>
              <span>
                ₹
                {Number(
                  cartItems
                    .reduce(
                      (total, item) =>
                        total +
                        parseInt(item.sp.replace(",", ""), 10) * item.quantity,
                      0
                    )
                    .toFixed(2) // Ensure two decimal places for precision
                ).toLocaleString("en-IN")}
              </span>
            </div>
          </div>
        </div>
        {/* total price and checkout section */}
        <div className={styles.bllingTotal}>
          {/* total amount section */}
          <div>
            <p className={styles.totalCost}>
              ₹
              {Number(
                cartItems
                  .reduce(
                    (total, item) =>
                      total +
                      parseInt(item.sp.replace(",", "")) * item.quantity,
                    0
                  )
                  .toFixed(2) // Ensure two decimal places for precision
              ).toLocaleString("en-IN")}
            </p>
            {/* <div className={styles.savings}>You are saving ₹1899</div> */}
            {/* <div className={styles.savings}>
              You Saved ₹
              {cartItems
                .reduce(
                  (totalSavings, item) =>
                    totalSavings +
                    (parseInt(item.cp.replace(",", "")) -
                      parseInt(item.sp.replace(",", ""))) *
                      item.quantity,
                  0
                )
                .toLocaleString("en-IN")}
            </div> */}
          </div>
          {/* checkout button */}
          <div>
            <CustomButton
              text="Checkout"
              backgroundColor="#C6349B"
              borderRadius="50px"
              borderColor="1px solid #CCCCCC"
              width="212px"
              height="48px"
              textColor="#fff"
              iconColor="#272727"
              fontSize="16px"
              fontFamily="Raleway"
              fontWeight="550"
              onClick={handleCheckout} // Call the checkout handler
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
