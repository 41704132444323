import React, { useState, useEffect, useRef } from "react";
import "./AllTrackCard.css";
import TrackCard from "./TrackCard";
import TrackFilter from "./TrackFilter";

const FilterTrackCards = ({
  filterTrackData,
  onLoadMore,
  isLoading,
  hasMoreData,
  scrollToTopRef, // Pass down ref to allow external scroll reset
  fetchResults, // Receive fetchResults function as prop
  searchKeyword, // Receive searchKeyword as prop
  trackHeading,
}) => {
  // console.log(trackHeading, "16");

  const [allTrackList, setAllTrackList] = useState([]);
  const [isFilterApplied, setFilterApplied] = useState(false); // Track if filters are applied
  const observerRef = useRef(); // for pagination

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isLoading && hasMoreData) {
          onLoadMore(); // Trigger load more when intersecting
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [isLoading, onLoadMore, hasMoreData]);

  // UseEffect to handle data update once when `filterTrackData` is available from search
  useEffect(() => {
    if (filterTrackData) {
      setAllTrackList(filterTrackData); // Set track data once on mount
    }
  }, [filterTrackData]); // Re-run when `filterTrackData` changes

  // fucntion to format the data for track cards
  const allTracks = allTrackList.map((track) => {
    // console.log(track, "track");
    const { costPrice, sellingPrice } = track.SKU[0]; // Extract costPrice and sellingPrice
    const discountPercent = (
      ((costPrice - sellingPrice) / costPrice) *
      100
    ).toFixed(0); // Calculate discount percentage

    // const artistNames = track.primaryArtists?.length
    //   ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
    //   : "";

    return {
      image: track.imageUrl,
      title:
        track.name.length > 18
          ? track.name.substring(0, 17) + "..."
          : track.name,
      description:
        track.artistNames.length > 30
          ? track.artistNames.substring(0, 28) + "..."
          : track.artistNames,
      buttonText: "Explore",
      buttonLink: "#",
      discountPercent: discountPercent || "50% OFF",
      costPrice: costPrice.toLocaleString() || "19,999",
      sellingPrice: sellingPrice.toLocaleString() || "79,000",
      name: track.name_slug,
      trackCode: track.trackCode,
      trackUrl: track.trackUrl,
    };
  });

  //   console.log(allTracks, "allTracks");

  // Callback to update the track list when filters are applied
  const handleFiltersApplied = (filteredTracks) => {
    // console.log(filteredTracks, "filteredTracks");
    setAllTrackList(filteredTracks);
    setFilterApplied(true);
  };

  const handleClearFilters = () => {
    setFilterApplied(false);
    // Call the API to fetch the unfiltered results
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollTo(0, 0); // Scroll to top
    }
    fetchResults(searchKeyword, 1, true); // Reset the results to initial data
  };

  return (
    <div ref={scrollToTopRef} className="all-songs-container inner">
      <div className="all-songs-filter">
        <TrackFilter
          onFiltersApplied={handleFiltersApplied}
          isFilterApplied={isFilterApplied}
          onClearFilters={handleClearFilters} // Pass callback for clearing
        />
      </div>
      <div className="inner">
        <div className="alltrack-title">
          <p className="alltrack-title-text-heading">
            {trackHeading ? `${trackHeading}` : "All Tracks"}
          </p>
          {/* <Link to={"/tracks"}>
            <div className="alltrack-viewall">
              <p className="alltrackview-heading">View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div>
          </Link> */}
        </div>
        {/* <div className="all-songs all-songs-filter-track">
          <TrackCard trackCard={allTracks} />
          {isFilterApplied === false && (
            <div ref={observerRef} style={{ height: "1px" }} />
          )}
        </div> */}
        {allTracks.length === 0 ? (
          <p style={{ margin: 0, textAlign: "center", marginTop: "20px" }}>
            No more data found
          </p>
        ) : (
          <>
            <TrackCard trackCard={allTracks} />
            {isFilterApplied === false && (
              <div ref={observerRef} style={{ height: "1px" }} />
            )}
          </>
        )}
        {isLoading && (
          <p style={{ margin: 0, textAlign: "center", marginTop: "20px" }}>
            Loading...
          </p>
        )}
        {/* {hasMoreData === false && (
          <p style={{ margin: 0, textAlign: "center", marginTop: "20px" }}>
            No more data found
          </p>
        )} */}
        {hasMoreData === false && allTracks.length !== 0 && (
          <p style={{ margin: 0, textAlign: "center", marginTop: "20px" }}>
            No more data found
          </p>
        )}
      </div>
    </div>
  );
};

export default FilterTrackCards;
