import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import "../styles/Faq.css";

const faqData = [
  {
    name: "Catalogue",
    questions: [
      {
        text: "What is Hoopr Smash?",
        answer:
          "Hoopr Smash is our premium music licensing service that provides high-quality, licensed music for businesses, brands, and creators. With Hoopr Smash, you can access exclusive and trending tracks for your projects, ensuring you have the legal rights to use the music worry-free.",
      },
      {
        text: "Why do I need a music license?",
        answer:
          "A music license protects you from copyright infringement issues and ensures you’re legally authorised to use the music in your content. Using unlicensed music can lead to takedown notices, legal fees, or other penalties. Licensing ",
      },
      {
        text: "What are the steps to license music on Hoopr Smash?",
        answer:
          "Select the music track(s) you’d like to license.\nAdd your selection of music track(s) to the cart.\nCheckout and complete the payment process.",
      },
      {
        text: "What are the benefits of licensing music with Hoopr Smash?",
        answer:
          "Legal Protection: You avoid potential copyright claims and legal issues.\nSimplified Process: Hoopr Smash manages all the licensing paperwork, making it easy for you.\nCost Savings: Licensing music saves you up to 10x in potential legal costs compared to using unlicensed music.\nQuality Tracks: Access a curated Selection of professionally produced music that enhances your content.",
      },
    ],
  },
  {
    name: "General",
    questions: [
      {
        text: "How is Hoopr Select different from Hoopr?",
        answer:
          "Hoopr Select offers licensing solutions for premium music from Bollywood movies and major labels in India, whereas Hoopr provides music curated from its vast network of over 6,500 independent artists. Hoopr uses a subscription model for music licensing, offering access to 12,500+ tracks, non-vocals, background music, and sound effects. Hoopr Select, on the other hand, features premium Bollywood music with licensing options for individual tracks or packs.",
      },
      {
        text: "Where can I use music from Hoopr Select?",
        answer:
          "Once you obtain the license, you can use Hoopr Select music in digital ad campaigns, films, radio, and other media.",
      },
      {
        text: "Will there be a subscription for business users on Hoopr Select?",
        answer:
          "No, we do not charge a subscription fee for streaming and discovering music on Hoopr Select. Users must fill out the Requirement Form so we can generate a custom quote tailored to their needs.",
      },
      {
        text: "Is there a limit on licensing songs/music on Hoopr Select?",
        answer:
          "No, there is no limit to the number of songs you can license at a time. However, usage will be governed by the terms of the agreement between Hoopr and the licensee.",
      },
      {
        text: "Can I edit the music to fit my project under the same license?",
        answer:
          "No, editing the music violates the agreement between the label/artist, Hoopr, and the licensee. This is currently not allowed.",
      },
    ],
  },
  {
    name: "License",
    questions: [
      {
        text: "Why Do Licenses Matter?",
        answer:
          "Artists should be compensated for their music. Licensing songs gives them the legal right to earn money when others use their work. For brands and businesses, it helps protect brand reputation and avoid legal issues.",
      },
      {
        text: "What kind of licenses or clearances does Hoopr Select provide?",
        answer:
          "Hoopr Select offers customized licenses tailored to each user. These can include, but are not limited to, brand videos, ad campaigns, and more. Please get in touch with us for additional details.",
      },
      {
        text: "How can I license music?",
        answer:
          "Steps:\n1. Select the tracks you want to license.\n2. Mention your use case(s).\n3. Add the duration for which you need the license.\n4. Specify the region for clearances.\n5. Mention any specific requirements.",
      },
      {
        text: "How long is my license valid once I license music from Hoopr Select?",
        answer:
          "You can specify the license duration when requesting a quote. The agreement will be drawn up based on the user's requirements and stated use cases.",
      },
      {
        text: "Can we get a song from a specific label on request?",
        answer:
          "Yes, you can share your request if you are looking to license a track from a specific label. Once the request is shared, our team will get in touch with you.",
      },
      {
        text: "Do I have to pay royalties even if I pay the licensing fee to Hoopr Select?",
        answer:
          "No, you do not need to pay music royalties separately if you have licensed music from Hoopr Select.",
      },
    ],
  },
  {
    name: "Support",
    questions: [
      {
        text: "I didn't use the music I paid for. Can I get a refund?",
        answer:
          "No, refunds are not possible after purchasing the license. Please ensure the music is used within the licensed duration.",
      },
      {
        text: "Will I get copyright claims or strikes if I use licensed music on YouTube?",
        answer:
          "We clear claims associated with tracks you have licensed if the video is uploaded after the license date. Share your channel details with us to ensure claims are cleared.",
      },
      {
        text: "What payment methods are accepted?",
        answer:
          "We accept IMPS, NEFT, RTGS, cheque, DD, and UPI payments. Cash payments are not accepted.",
      },
      {
        text: "Will I get a reminder when my licensing period is coming to an end?",
        answer:
          "Yes, you will be informed when the licensing period is nearing its end.",
      },
      {
        text: "Who can I contact for technical issues?",
        answer:
          "Please get in touch with us at support@example.com or call us at +1-800-555-1234.",
      },
    ],
  },
  {
    name: "All",
    questions: [
      {
        text: "What is Hoopr Smash?",
        answer:
          "Hoopr Smash is our premium music licensing service that provides high-quality, licensed music for businesses, brands, and creators. With Hoopr Smash, you can access exclusive and trending tracks for your projects, ensuring you have the legal rights to use the music worry-free.",
      },
      {
        text: "Why do I need a music license?",
        answer:
          "A music license protects you from copyright infringement issues and ensures you’re legally authorised to use the music in your content. Using unlicensed music can lead to takedown notices, legal fees, or other penalties. Licensing ",
      },
      {
        text: "What are the steps to license music on Hoopr Smash?",
        answer:
          "Select the music track(s) you’d like to license.\nAdd your selection of music track(s) to the cart.\nCheckout and complete the payment process.",
      },
      {
        text: "What are the benefits of licensing music with Hoopr Smash?",
        answer:
          "Legal Protection: You avoid potential copyright claims and legal issues.\nSimplified Process: Hoopr Smash manages all the licensing paperwork, making it easy for you.\nCost Savings: Licensing music saves you up to 10x in potential legal costs compared to using unlicensed music.\nQuality Tracks: Access a curated Selection of professionally produced music that enhances your content.",
      },
      {
        text: "How does the cost of licensed music compare to unlicensed music?",
        answer:
          "Simply click on the Get a Quote button on the Hoopr Smash page, and our team will reach out with pricing and details.\nFor more information on music rights and licensing, visit our resources section or reach out directly to the Hoopr Smash team.",
      },
      {
        text: "How often do you add new songs to your catalogue?",
        answer:
          "The catalogue is updated in real-time with new songs frequently. We are constantly acquiring new music and onboarding new artists onto the platform.",
      },
      {
        text: "Can I license a song that is currently not present on the platform?",
        answer:
          "Currently, we have music from select labels. However, we're always adding new music and artists to the platform. If you don’t find what you’re looking for, please share your request so we can consider it for future onboarding.",
      },
      {
        text: "What types of catalogues does Hoopr Smash offer?",
        answer:
          "We offer a wide collection of premium Bollywood music from top Indian labels like YRF and Saga. Our catalogue includes various genres, popular artists, and trending songs.",
      },
      {
        text: "Will there be any public-domain music on Hoopr Smash for licensing?",
        answer:
          "No, currently we don’t offer public-domain music on Hoopr Smash.",
      },
      {
        text: "How is Hoopr Smash different from Hoopr?",
        answer:
          "Hoopr Smash offers licensing solutions for premium Bollywood music from major labels, while Hoopr has music curated from its network of 6,500+ independent artists. Hoopr offers a subscription-based model, giving access to over 12,500 music tracks, non-vocals, background music, and sound effects. Hoopr Smash, however, provides Bollywood music licensing on a per-track or pack basis, customized to fit specific requirements.",
      },
      {
        text: "Where can I use music from Hoopr Smash?",
        answer:
          "With a license, you can use Hoopr Smash music in digital ad campaigns, films, radio, and other media resources.",
      },
      {
        text: "Will there be a subscription for business users on Hoopr Smash?",
        answer:
          "No, we don’t charge a subscription fee for streaming and discovering on Hoopr Smash. Users need to fill out a Requirement Form to receive a custom quote for their specific needs.",
      },
      {
        text: "Is there a limit on licensing songs/music on Hoopr Smash?",
        answer:
          "No, there is no limit to the number of songs one can license at a time. However, usage is subject to the terms of the agreement between Hoopr Smash and the licensee.",
      },
      {
        text: "Can I edit the music to fit my project under the same license?",
        answer:
          "No, editing the music violates the terms of the agreement between the label/artist, Hoopr Smash, and the licensee. Editing is currently not permitted.",
      },
      {
        text: "Why do licenses matter?",
        answer:
          "Licensing compensates artists for their work. For brands and businesses, it helps protect brand reputation and avoids legal issues by ensuring proper rights usage.",
      },
      {
        text: "What kind of licenses or clearances does Hoopr Smash provide?",
        answer:
          "Hoopr Smash offers bespoke licenses customized to fit each user's needs, including brand videos, ad campaigns, and more. Contact us for additional details.",
      },
      {
        text: "How can I license music?",
        answer:
          "Steps:\nSelect the tracks you want to license.\nSpecify your use case(s).\nAdd the duration needed.\nIndicate the region for clearances.\nMention specific requirements (if any).",
      },
      {
        text: "How long is my license valid once I license music from Hoopr Smash?",
        answer:
          "You can specify the license duration when requesting a quote. The agreement will be tailored to your requirements and use cases.",
      },
      {
        text: "Can we request a song from a specific label?",
        answer:
          "Yes, you can request a track from a specific label. Once shared, our team will reach out to discuss options.",
      },
      {
        text: "Do I need to pay royalties if I’ve paid the licensing fee to Hoopr Smash?",
        answer:
          "No, you don’t have to pay additional royalties if you’ve licensed music from Hoopr Smash.",
      },
      {
        text: "What is the average approval wait time?",
        answer:
          "The average wait time is 2-3 business days, but this may vary depending on the rights holders. We’ll keep you updated on the process.",
      },
      {
        text: "How does Hoopr Smash legally license this music?",
        answer:
          "Hoopr works with labels and music owners to offer authorized licensing for soundtracks available on Hoopr Smash.",
      },
      {
        text: "Can I cancel or modify a licensing request?",
        answer:
          "Yes, cancellation or modification is possible until the agreement is signed. After signing, changes are not allowed.",
      },
      {
        text: "Will I receive legal documentation as proof of licensing a song?",
        answer:
          "Yes, a licensing agreement detailing the terms of usage will be provided.",
      },
      {
        text: "Can individual creators license Bollywood music on Hoopr Smash?",
        answer:
          "Yes, individual creators can license Bollywood music. You can specify your use case when requesting a quote.",
      },
      {
        text: "Are licenses valid worldwide?",
        answer:
          "Licenses can be cleared for worldwide use, though the terms will be tailored per case to meet your specific needs.",
      },
      {
        text: "Do I need a license to use music on a personal website?",
        answer:
          "Yes, a license is required to use music on your personal website.",
      },
      {
        text: "Can I use the same license across multiple campaigns?",
        answer:
          "Yes, as long as each use is included in the agreement. Uses outside the agreement’s scope will not be allowed.",
      },
      {
        text: "Can I transfer my license to someone else?",
        answer:
          "No, licenses are non-transferable and are tied to the specific brand/organization that signs the agreement.",
      },
      {
        text: "Can I view my past licenses?",
        answer:
          "Yes, all licensing agreements will be provided to you for record-keeping.",
      },
      {
        text: "Can I license songs for ad campaigns?",
        answer:
          "Yes, custom licenses can include songs for ad campaigns. Contact us for details on licensing for specific uses.",
      },
      {
        text: "Are licensing costs different for various media types (e.g., TV, online, radio)?",
        answer: "Yes, costs vary based on the media type and use case.",
      },
      {
        text: "Are there different rates for non-profit projects?",
        answer:
          "Yes, you can request a quote and include details about the non-profit project’s use case.",
      },
      {
        text: "Can I get a refund if I didn’t use the licensed music?",
        answer:
          "No, refunds are not possible once a license is purchased. Please ensure your usage within the licensed duration.",
      },
      {
        text: "Will I receive copyright claims or strikes on YouTube for licensed music?",
        answer:
          "We will clear claims for videos using tracks you’ve licensed from Hoopr Smash if uploaded after purchase. Share your channel details, and we’ll handle any claims within the licensed duration.",
      },
      {
        text: "What payment methods are accepted?",
        answer:
          "All payment methods such as IMPS, NEFT, RTGS, Cheque, DD, and UPI are accepted. Cash payments are not accepted.",
      },
      {
        text: "Will I get a reminder before my licensing period ends?",
        answer: "Yes, we will notify you before the licensing period ends.",
      },
      {
        text: "Who can I contact for technical support?",
        answer:
          "Please contact us via email at [email address] or call us at [phone number].",
      },
      {
        text: "How does Hoopr Smash handle licensing disputes?",
        answer:
          "Hoopr Smash ensures claim clearance for licensed tracks through agreements with music labels. If you face any issues, share the video URL, and we’ll resolve the claim.",
      },
      {
        text: "What should I do if I receive a copyright claim despite having licensed the music?",
        answer:
          "Contact us immediately with the content video URL. We’ll take care of any claim associated with your licensed track.",
      },
    ],
  },
];

const FAQPage = () => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  const toggleQuestion = (sectionIndex, questionIndex) => {
    setExpandedQuestion(
      expandedQuestion === `${sectionIndex}-${questionIndex}`
        ? null
        : `${sectionIndex}-${questionIndex}`
    );
  };

  return (
    <div className="outer-full-faq">
      <div className="inner">
        <h2 className="faq-heading">FAQs</h2>
        <div className="faq-container">
          {faqData.map((section, sectionIndex) => (
            <div key={sectionIndex} className="faq-section">
              <div
                className={`faq-section-header ${
                  expandedSection === sectionIndex ? "active" : ""
                }`}
                onClick={() => toggleSection(sectionIndex)}
                style={{ cursor: "pointer" }}
              >
                <span>{section.name}</span>
                {expandedSection === sectionIndex ? (
                  <IoIosArrowUp size={24} className="icon" />
                ) : (
                  <IoIosArrowDown size={24} className="icon" />
                )}
              </div>

              {expandedSection === sectionIndex && (
                <div className="faq-questions">
                  {section.questions.map((question, questionIndex) => (
                    <div key={questionIndex} className="faq-question">
                      <div
                        className={`faq-question-header ${
                          expandedQuestion ===
                          `${sectionIndex}-${questionIndex}`
                            ? "active-question"
                            : ""
                        }`}
                        onClick={() =>
                          toggleQuestion(sectionIndex, questionIndex)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <span>{question.text}</span>
                        {expandedQuestion ===
                        `${sectionIndex}-${questionIndex}` ? (
                          <FaMinus className="icon-plus" size={18} />
                        ) : (
                          <FaPlus className="icon-plus" size={18} />
                        )}
                      </div>

                      {expandedQuestion ===
                        `${sectionIndex}-${questionIndex}` && (
                        <div className="faq-answer">{question.answer}</div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
