import React, { useState, useContext } from "react";
import "./TrackCard.css";
import { useGlobalPlayer } from "../../../utils/GlobalPlayerContext";
import { RiShoppingCart2Line } from "react-icons/ri";
import CustomButton from "../../CustomButton/CustomButton";
import { FaPlay, FaPause } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { addToCart } from "../../../utils/redux/cartSlice";
import { useDispatch } from "react-redux";
import { ToastContext } from "../../../utils/ToastContext";
import randomDefaultImage from "../../../utils/RandomDefaultImage";

const TrackCard = ({
  trackCard,
  trackType,
  btnWidth,
  btnHeight,
  btnSize,
  cartBgColor,
  cartIgColor,
  borderColor,
}) => {
  const [activeTrackCode, setActiveTrackCode] = useState(null); // Track currently playing
  const { isPlaying, setIsPlaying, showPlayer } = useGlobalPlayer();
  const dispatch = useDispatch(); // Hook to dispatch actions
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage

  // console.log(trackCard, "17");

  // function to get the details of the track trigger
  const handleTrackClick = (track) => {
    // console.log("Track Details:", track);
    const trackInfo = {
      trackImg: track.image,
      trackName: track.title,
      trackArtistName: track.description,
      trackDuration: "03:25",
      trackCode: track.trackCode,
      trackUrl: track.trackUrl,
      trackJson: track.waveJson,
    };
    if (isPlaying === true) {
      //   console.log("254", isPlaying);
      showPlayer(trackInfo);
      setIsPlaying(isPlaying);
    }
    showPlayer(trackInfo);

    // Toggle play/pause state
    // console.log(isPlaying, "257");

    setActiveTrackCode(track.trackCode);
  };

  const handleCartClick = (track) => {
    // console.log(track, "track info for cart");
    const cartInfo = {
      cardType: "track", // card type to display
      preText: track.title, // track name
      secText: track.description, // artist name
      code: track.trackCode, // track code
      sp: track.sellingPrice, // selling price
      cp: track.costPrice, // cost price
      cardImg: track.image, // card img based on track
    };
    // setCartDetails(cartInfo);
    // Dispatch action to add the track to the cart
    dispatch(addToCart(cartInfo));
    showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg

    // console.log(cartInfo, "cartInfo");
  };

  // --------------- data to be format in fashion for track card -------------------------------//

  // ------- > sample format for track card data
  //   const allTracks = allTrackList.map((track) => {
  //     const { costPrice, sellingPrice } = track.SKU[0]; // Extract costPrice and sellingPrice
  //     const discountPercent = (
  //       ((costPrice - sellingPrice) / costPrice) *
  //       100
  //     ).toFixed(0); // Calculate discount percentage

  //     const artistNames = track.primaryArtists?.length
  //       ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
  //       : "";

  //     return {
  //       image: track.imageUrl || randomDefaultImage("track"),
  //       title:
  //         track.name.length > 18
  //           ? track.name.substring(0, 17) + "..."
  //           : track.name,
  //       description:
  //         artistNames.length > 30
  //           ? artistNames.substring(0, 29) + "..."
  //           : artistNames,
  //       buttonText: "Explore",
  //       buttonLink: "#",
  //       discountPercent: discountPercent || "50% OFF",
  //       costPrice: costPrice.toLocaleString() || "19,999",
  //       sellingPrice: sellingPrice.toLocaleString() || "79,000",
  //       name: track.name_slug,
  //       trackCode: track.trackCode,
  //       //   trackUrl: getDirectLink(track.link),
  //       trackUrl:
  //         track.mp3Link || getDirectLink(track.link) || track.waveformLink,
  //     };
  //   });

  // --------------- data to be format in fashion for track card -------------------------------//

  return (
    <div className="allsong-track-card-container" data-type={trackType}>
      {trackCard.map((track, index) => (
        <div key={index} className="allsong-track-card">
          <div
            className="allsong-track-card-image"
            style={{
              backgroundImage: `url(${
                track.image || randomDefaultImage("track")
              })`,
            }}
            onClick={() => {
              handleTrackClick(track);
              setIsPlaying(!isPlaying);
            }}
          >
            <div className="track-play-btn-container">
              {activeTrackCode === track.trackCode && isPlaying ? (
                <FaPause color="#C6349B" size={24} />
              ) : (
                <FaPlay color="#C6349B" size={24} />
              )}
            </div>
          </div>
          <div className="allsong-track-card-text">
            <div className="allsong-track-title">
              <Link
                to={`/tracks/${encodeURIComponent(track.name)}/${
                  track.trackCode
                }`}
              >
                <h1>{track.title}</h1>
              </Link>
              <h2>{track.description}</h2>
            </div>
            {/* Price section with conditional rendering */}
            <div className="allsong-track-price-container">
              {/* Only show discount and cost price if sellingPrice != costPrice */}
              {track.sellingPrice !== track.costPrice && (
                <div className="allsong-track-discount-price">
                  <div className="allsong-track-discount-amount">
                    <span>₹</span>
                    <p>{track.sellingPrice}</p>
                  </div>
                  <div className="allsong-track-discount-percentage">
                    {track.discountPercent}% Off
                  </div>
                </div>
              )}

              {/* Only show cost price if sellingPrice != costPrice */}
              {track.sellingPrice !== track.costPrice && (
                <div className="allsong-track-cost-price">
                  <span>₹{track.costPrice}</span>
                </div>
              )}
            </div>
            <div className="allsong-explore-track-button">
              <div>
                <Link
                  to={`/tracks/${encodeURIComponent(track.name)}/${
                    track.trackCode
                  }`}
                >
                  <CustomButton
                    text={track.buttonText}
                    backgroundColor="#fff"
                    borderRadius="50px"
                    borderColor="1px solid #CCCCCC"
                    // width="148px"
                    width={btnWidth || "194px"}
                    // height="29px"
                    height={btnHeight || "37px"}
                    textColor="#272727"
                    iconColor="#272727"
                    // fontSize="13px"
                    fontSize="16px"
                    fontFamily="Inter"
                    fontWeight="550"
                    icon={<IoIosArrowForward />}
                    justifyContent="space-between"
                  />
                </Link>
              </div>
              <div>
                <CustomButton
                  icon={<RiShoppingCart2Line />}
                  isIconOnly={true}
                  backgroundColor={cartBgColor || "#272727"}
                  iconColor={cartIgColor || "#fff"}
                  borderRadius="50px"
                  height={btnSize || "37px"}
                  width={btnSize || "37px"}
                  onClick={() => {
                    // setIsCartOpen(true);
                    handleCartClick(track);
                  }}
                  borderColor={borderColor || "rgb(0, 0, 0)"}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrackCard;
