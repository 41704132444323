import config from "./config";

const RandomDefaultImage = (type) => {

  //  ALLOW TYPE TO COME IN ANY CASE, RMEOVE DEVELOPER OVERHEAD
  type = type.toLowerCase();
  // console.log(type);

  //  SET BASE FOLDER PATH FOR THE IMAGES
  const awsBaseURL = `${config.s3imgUrl}web/default-img/`;

  //  LIST OF IMAGES WHICH WILL REPLACE SECTIONAL IMAGES AS NEEDED
  const aSquare = ["square1.png", "square2.png", "square3.png", "square4.png"];
  const aTracks  = ["track1.png", "track2.png", "track3.png", "track4.png"];
  const aGenre  = ["genre1.png", "genre2.png", "genre3.png", "genre4.png"];
  const aPacks  = ["pack1.png", "pack2.png", "pack3.png", "pack4.png", "pack5.png"];

  let useArray = [];

  //  SWITCH CASE WAS PREFERRED INSTEAD OF THE IF-ELSE CLAUSES
  switch (type) {
    case 'square':
      useArray = aSquare;
      break;
    case 'genre':
      useArray = aGenre;
      break;
    case 'pack':
      useArray = aPacks;
      break;
    case 'track':
        useArray = aTracks;
      break;
    default:
      useArray = aSquare;
  }

  //  GET A RANDOM INDEX AS PER THE ARRAY LENGTH
  const randomIndex = Math.floor(Math.random() * useArray.length);

  //  RETURN ONE OF THE IMAGES FROM THE ARRAY
  return awsBaseURL + useArray[randomIndex];
};

export default RandomDefaultImage;
