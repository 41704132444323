import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../styles/SearchPage.css"; // Assuming your styles are here
import FilterTrackCards from "../components/CardsComponent/TrackCard/FilterTrackCard";
import { getSearchResultFaceted } from "../service/SearchService";
import getDirectLink from "../utils/AudioUrlFormated";
import randomDefaultImage from "../utils/RandomDefaultImage";

const SearchPage = () => {
  const [activeTab, setActiveTab] = useState("Track"); // Default tab is 'Track'
  const [searchParams] = useSearchParams();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page for API
  const [hasMoreData, setHasMoreData] = useState(true); // Flag to track if more data is available
  const searchKeyword = searchParams.get("searchKeyword");
  const scrollToTopRef = useRef(null); // Ref for scrolling

  useEffect(() => {
    if (searchKeyword) {
      setPage(1); // Reset page when keyword changes
      setHasMoreData(true); // Reset data availability flag
      fetchResults(searchKeyword, 1, true); // Fetch first page
      // Scroll to top when keyword changes
      if (scrollToTopRef.current) {
        scrollToTopRef.current.scrollTo(0, 0);
      }
    }
  }, [searchKeyword]);

  // console.log(searchKeyword, "31");

  // Function to fetch search results
  const fetchResults = async (query, page, reset = false) => {
    try {
      if (!hasMoreData || isLoading) return; // Stop fetching if no more data or already loading
      setIsLoading(true);

      const payload = {
        asset_type: "music",
        artists: [],
        bpm: { min: 30, max: 110 },
        genres: [],
        languages: [],
        moods: [],
        playlists: [],
        sfxcategories: [],
        sfxsubcategories: [],
        subgenres: [],
        usecases: [],
      };

      const data = await getSearchResultFaceted(query, payload, page);
      // console.log(data, "54");

      if (data.length === 0) {
        setResults([]);
        setHasMoreData(false); // No more data available
      } else {
        // Append or reset results
        setResults((prev) => (reset ? data : [...prev, ...data]));
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setIsLoading(false);
    }
  };

  //   console.log(results, "results");

  const tracksData = results.filter((item) => item.type === "music");

  // Create an array of objects with selected properties
  const allTrackList =
    tracksData?.map((track) => ({
      imageUrl:
        track.image_url != "" && track.image_url != null
          ? track.image_url
          : randomDefaultImage("track"),
      name: track.name,
      name_slug: track.name_slug,
      trackCode: track.track_code,
      tracks_artist_role: track.tracks_artist_role,
      SKU: [
        {
          costPrice: 69999,
          sellingPrice: 49999,
          gstPercent: 18,
          maxUsage: 3,
        },
      ],
      //   artistNames:
      //     track.primaryArtists?.length > 0
      //       ? track.primaryArtists.map((artist) => artist.name).join(", ")
      //       : "",
      artistNames: JSON.parse(track.tracks_artist_role || "[]") // Ensure valid JSON
        // .map((role) => role.artist?.name || "")
        // .join(", ");
        .filter((item) => item.role === "primary")
        .map((item) => item.artist.name)
        .join(", "),
      trackUrl:
        track.mp3_link || getDirectLink(track.link) || track.waveform_link,
    })) || [];

  // console.log(allTrackList, "allTrackList");

  // Handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Function to load more results (called by FilterTrackCards)
  const loadMoreResults = () => {
    if (!isLoading && hasMoreData) {
      setPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchResults(searchKeyword, nextPage);
        return nextPage;
      });
    }
  };

  return (
    <div className="search-page-container inner">
      {/* Tabs Header */}
      <div className="search-tabs">
        <span
          className={`tab ${activeTab === "Track" ? "active" : ""}`}
          onClick={() => handleTabClick("Track")}
        >
          Track
        </span>
        {/* <span
          className={`tab ${activeTab === "Packs" ? "active" : ""}`}
          onClick={() => handleTabClick("Packs")}
        >
          Packs
        </span> */}
        {/* <span
          className={`tab ${activeTab === "Playlist" ? "active" : ""}`}
          onClick={() => handleTabClick("Playlist")}
        >
          Playlist
        </span> */}
      </div>

      {/* Content for active tab */}
      <div className="tab-content">
        {activeTab === "Track" && (
          <FilterTrackCards
            filterTrackData={allTrackList}
            onLoadMore={loadMoreResults}
            isLoading={isLoading}
            hasMoreData={hasMoreData}
            scrollToTopRef={scrollToTopRef} // Pass down ref
            // trackHeading={searchKeyword}
            trackHeading={`Search Result for ${searchKeyword}`}
          />
        )}
        {activeTab === "Packs" && (
          <div>
            {/* Content related to Packs */}
            <p>No Data Found</p>
          </div>
        )}
        {activeTab === "Playlist" && (
          <div>
            {/* Content related to Playlist */}
            <p>No Data Found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
