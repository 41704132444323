import React from "react";
import "./PackCard.css";
import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import randomDefaultImage from "../../../utils/RandomDefaultImage";


const CardComponent = ({ packCards, packType, showPrice = true }) => {
  // console.log(packCards);

  return (
    <div className="packs-cards-container" data-type={packType}>
      {packCards.map((pack, index) => (
        <div key={index} className="pack-card">
          <div className="pack-title">
          <Link
          to={`/playlists/pack-${encodeURIComponent(
             pack.packNameSlug
           )}/${pack.packCode}`}>
            <h1 className="pack-heading">{pack.title}</h1>
            </Link>
            {/* <span>Pack of {pack.totalTracks} tracks</span> */}
            <span>{pack.totalTracks} tracks</span>
            </div>

          <Link
          to={`/playlists/pack-${encodeURIComponent(
             pack.packNameSlug
           )}/${pack.packCode}`}>
          <div
            className="pack-card-img"
            style={{
              // backgroundImage: `url(${pack.image})`,
              backgroundImage: pack.image != ''
              ? `url(${pack.image})`
              : `url(${randomDefaultImage('pack')})`,
            }}
            >
            <div className="card-like-info">
              <div className="like-icon">
                <FaRegHeart size={24} color="#fff" />
              </div>
              {/* <div className="card-track-total">
                <p>{pack.totalTracks}</p>
                <span>TRACKS</span>
                </div> */}
            </div>
          </div>
        </Link>

          <div className="pack-price-button">
            <div className="pack-price-container">
              <div className="pack-discount-price">
                <div className="pack-discount-amount">
                  <span>₹</span>
                  <p>99,999</p>
                </div>
                {showPrice && (
                  <div className="pack-discount-percentage">23% Off</div>
                )}
              </div>
              {showPrice && (
                <div className="pack-cost-price">
                  {/* <p>Cost:</p> */}
                  <span>₹1,29,999</span>
                </div>
              )}
            </div>
            {showPrice && (
              <div className="explore-track-button">
                <Link
                  to={`/playlists/pack-${encodeURIComponent(
                    pack.packNameSlug
                  )}/${pack.packCode}`}
                  className="explore-pack-btn"
                >
                  {pack.buttonText}
                  <IoIosArrowForward color="#C6349B" size={20} />
                </Link>

                <button className="explore-pack-cart-btn">
                  {<RiShoppingCart2Line color="#fff" size={20} />}
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardComponent;
