import config from "../config";

const categories = {
  "eafd60a8-c0ab-4504-a3af-96ed441d267a": {
      "name": "Advertising",
      "description": "High-energy tracks and jingles to elevate your brand's visibility and create memorable campaigns. Use cases: TV commercials, radio ads, digital ads.",
      "img": `${config.s3imgUrl}web/home/categories/ad.jpg`
  },
  "5b53b4ff-a802-4b5d-bcef-6ec3fcd727c8": {
      "name": "Automobile",
      "description": "",
      "img": ""
  },
  "c63cbc85-930c-40bd-8e8c-84f252c4f7f6": {
      "name": "Background Scores",
      "description": "Subtle and evocative background music to enhance the mood and atmosphere of your videos and presentations. Use cases: Documentaries, corporate videos, explainer videos.",
      "img": ""
  },
  "5a1e36b9-614b-4540-a81c-6ad79db245b0": {
      "name": "Beauty & Skincare",
      "description": "Elegant and sophisticated music for beauty and skincare content. Use Cases: Beauty vlogs, makeup tutorials, skincare reviews, hair tutorials, product reviews",
      "img": ""
  },
  "ed70513b-a743-42ec-95a5-248c60e13800": {
      "name": "Bumpers ",
      "description": "Short, attention-grabbing sound effects and music stings to transition between segments in your videos. Use cases: YouTube videos, podcasts, live streams.",
      "img": ""
  },
  "64538398-f88b-431b-a63c-b79971fbed6a": {
      "name": "Comedy ",
      "description": "Funny and quirky music to add humor and levity to your comedic content. Use cases: Comedy sketches, stand-up comedy, satirical videos.",
      "img": ""
  },
  "1b708e79-bf38-476f-abbe-cf18fddd43cc": {
      "name": "Cooking",
      "description": "Upbeat and appetizing music to accompany your culinary content. Use cases: Food blogs, cooking shows, recipe videos.",
      "img": ""
  },
  "0e01f907-a8cd-4e71-9d73-81097fe21789": {
      "name": "Corporate ",
      "description": "Professional and sophisticated music to enhance your corporate communications and presentations. Use cases: Corporate videos, webinars, presentations.",
      "img": ""
  },
  "3c48a7fb-291e-4db9-bee0-526d36472585": {
      "name": "Devotional ",
      "description": "Soothing and spiritual music for religious and devotional content. Use cases: Religious videos, meditation apps, yoga videos.",
      "img": ""
  },
  "07f3896a-efc7-4d24-8a78-8b6a72d775ed": {
      "name": "E-Commerce",
      "description": "",
      "img": ""
  },
  "e20706f1-c89e-4500-9662-c68fdfa5a532": {
      "name": "Ed-Tech",
      "description": "Focused and engaging music to enhance learning and education. Use Cases: Educational videos, online courses, tutorials, explainer videos, webinars",
      "img": ""
  },
  "4d12fd79-69b5-4482-9a7d-61cfe521439c": {
      "name": "Fashion",
      "description": "Stylish and trendy music to complement your fashion and beauty content. Use cases: Fashion vlogs, beauty tutorials, product reviews.",
      "img": `${config.s3imgUrl}web/home/categories/fashion.jpg`
  },
  "c0d776d3-7457-4f6e-b357-d505a677e146": {
      "name": "Festive",
      "description": "Festive and celebratory music for special occasions and holidays. Use cases: Holiday videos, event promotions, social media posts.",
      "img": ""
  },
  "9f01440b-5e9a-4252-ba8e-b2b63f6fc185": {
      "name": "Film & OTT Content",
      "description": "Dramatic, cinematic, and emotive music for films and OTT content. Use Cases: Movie trailers, web series, documentaries, short films, TV commercials",
      "img": ""
  },
  "0ea970e0-a57e-40c9-b46e-2d04a74c20ab": {
      "name": "Gaming ",
      "description": "Energetic and immersive music to enhance your gaming experiences. Use cases: Gaming videos, live streams, game trailers.",
      "img": ""
  },
  "26f05bb3-c7a1-4409-a28e-6f9003c976d5": {
      "name": "Global Occasions",
      "description": "International music tracks to celebrate global events and cultures. Use cases: Travel vlogs, cultural documentaries, language learning videos.",
      "img": ""
  },
  "4f4624b6-9f8a-458d-82d1-77a844691856": {
      "name": "Health & Fitness",
      "description": "Motivational and energetic music to inspire your fitness and wellness content. Use cases: Workout videos, fitness apps, yoga tutorials.",
      "img": `${config.s3imgUrl}web/home/categories/health.jpg`
  },
  "b8e9fb8f-69fc-40c8-b1d0-09f6f81e92fa": {
      "name": "Indian Festivals ",
      "description": "Traditional and contemporary Indian music for celebrating Indian festivals. Use cases: Festival videos, cultural documentaries, devotional content.",
      "img": `${config.s3imgUrl}web/home/categories/if.jpg`
  },
  "11334cec-2010-4885-bc35-f0f995d42701": {
      "name": "Intros & Outros ",
      "description": "Catchy intro and outro music to grab your audience's attention and leave a lasting impression. Use cases: YouTube videos, podcasts, online courses.",
      "img": ""
  },
  "1d785d09-f059-4952-98eb-9c21d3864890": {
      "name": "Jingles",
      "description": "Short, catchy jingles to promote your brand or product. Use cases: TV commercials, radio ads, social media ads.",
      "img": ""
  },
  "f872e350-5430-46a1-aca0-af292a9c13b9": {
      "name": "Kids",
      "description": "Fun and playful music for children's content. Use cases: Children's videos, educational content, toys and games.",
      "img": ""
  },
  "90b1bc0c-42fa-4e6f-b6f3-1285f6a95b88": {
      "name": "Learning",
      "description": "Focussed and engaging music to enhance learning and education. Use cases: Online courses, educational videos, tutorials.",
      "img": ""
  },
  "f909fd19-7d11-4bb8-9ef0-33a1852b2278": {
      "name": "Meditation",
      "description": "Calming and relaxing music to promote mindfulness and relaxation. Use cases: Meditation apps, yoga videos, sleep aids.",
      "img": ""
  },
  "953971e2-fd76-416d-b570-c0ab566302c1": {
      "name": "Movies",
      "description": "Dramatic and cinematic music to elevate your film projects. Use cases: Short films, feature films, movie trailers.",
      "img": ""
  },
  "1e615e83-0a74-40b0-b65f-b4af467705e3": {
      "name": "OTT / Webshows",
      "description": "",
      "img": ""
  },
  "ba2205b8-c398-4e70-b65c-0f5df5a75adc": {
      "name": "Patriotic ",
      "description": "Inspiring and patriotic music to commemorate national events and holidays. Use cases: Patriotic videos, documentaries, historical content.",
      "img": ""
  },
  "84a88317-f88c-4432-85df-5ab81e8a1588": {
      "name": "Podcasts & Audiobooks",
      "description": "Engaging and immersive background music to enhance your audio content. Use cases: Podcasts, audiobooks, storytelling.",
      "img": ""
  },
  "c3461e1c-aa33-4c75-949a-bf84a80744db": {
      "name": "Product Launches",
      "description": "",
      "img": ""
  },
  "264c7ea7-fd2b-4485-9957-58a57f09e54d": {
      "name": "Promos & Trailers",
      "description": "Dynamic and attention-grabbing music to promote your products and services. Use cases: Product launches, event promotions, movie trailers.",
      "img": ""
  },
  "c5cbe26e-074b-447e-bf9f-256055854732": {
      "name": "Shorts & Reels",
      "description": "Short and snappy music tracks to create engaging short-form content. Use cases: TikTok videos, Instagram Reels, YouTube Shorts.",
      "img": ""
  },
  "538f6a59-7a05-46f9-8abf-d78a915549a1": {
      "name": "Shows",
      "description": "Versatile music tracks to enhance the mood and atmosphere of your TV shows and web series. Use cases: TV shows, web series, reality shows.",
      "img": ""
  },
  "21b41ad8-b66c-4a79-bd24-4485db9c7dbc": {
      "name": "Sports ",
      "description": "Energetic and exciting music to accompany sports highlights and events. Use cases: Sports broadcasts, sports documentaries, highlight reels.",
      "img": ""
  },
  "33b2bf23-f5b5-4398-aa51-7046a2922691": {
      "name": "Travel ",
      "description": "Adventurous and inspiring music to complement your travel content. Use cases: Travel vlogs, travel documentaries, destination marketing.",
      "img": ""
  },
  "4f95ba81-8a7d-453d-8a5d-325ca9b57feb": {
      "name": "Unboxing & Reviews",
      "description": "Upbeat and engaging music to enhance your product reviews and unboxing videos. Use cases: Product reviews, tech reviews, unboxing videos.",
      "img": ""
  },
  "575d7ac9-7165-49d8-a6aa-86e2ea1219a3": {
      "name": "Wedding",
      "description": "Romantic and celebratory music for weddings and receptions. Use cases: Wedding videos, wedding invitations, reception music.",
      "img": `${config.s3imgUrl}web/home/categories/wedding.jpg`
  }
};

export default categories;
