import React, { createContext, useState } from "react";

export const ToastContext = createContext();

export const useToast = () => {
  const [heading, setHeading] = useState("");
  const [message, setMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState("success");

  const showToast = (heading, msg, msgType = "success") => {
    setHeading(heading);
    setMessage(msg);
    setType(msgType);
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 3000);
  };

  const closeToast = () => {
    setIsVisible(false);
  };

  return { heading, message, isVisible, showToast, closeToast, type };
};

export const ToastProvider = ({ children }) => {
  const toast = useToast();

  return (
    <ToastContext.Provider value={toast}>{children}</ToastContext.Provider>
  );
};
