import React, { useEffect, useRef, useState } from "react";
import { IoIosPlay, IoIosPause } from "react-icons/io";
import { TbRewindForward10, TbRewindBackward10 } from "react-icons/tb";
import WaveSurfer from "wavesurfer.js";
import HoverPlugin from "wavesurfer.js/dist/plugins/hover.js";
import "../styles/Waveform.css";

const Waveform = ({
  trackUrl,
  play,
  onPlayPauseChange,
  showControls = true,
  height,
  marginLeft,
  waveJson,
}) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null); // Reference to the WaveSurfer instance
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state for waveform
  const prevTrackUrlRef = useRef(trackUrl); // This will store the previous value of trackUrl
  const [jsonData, setJsonData] = useState(null); // json data of the track

  useEffect(() => {
    const fetchWaveJson = async () => {
      try {
        const response = await fetch(waveJson);
        const json = await response.json();
        console.log("Fetched WaveJson:", json);
        setJsonData(json); // Assuming waveJson is managed via state
      } catch (error) {
        console.error("Error fetching waveform JSON:", error);
      }
    };

    fetchWaveJson();
  }, [waveJson]);

  // console.log();

  //   console.log("Previous trackUrl:", prevTrackUrlRef.current);
  //   console.log("Current trackUrl:", trackUrl);
  //   console.log(isPlaying, "isPlaying");
  //   console.log(play, "play 26");

  useEffect(() => {
    // console.log("Previous trackUrl 27:", prevTrackUrlRef.current);
    // console.log("Current trackUrl27:", trackUrl);
    if (trackUrl !== prevTrackUrlRef.current) {
      //   console.log("trackUrl has changed, cleaning up the previous instance");
      setIsLoading(true);

      if (waveSurferRef.current) {
        waveSurferRef.current.destroy(); // Destroy the previous instance
        waveSurferRef.current = null; // Set it to null after destroying
      }
      prevTrackUrlRef.current = trackUrl; // Update the previous trackUrl reference
    }

    // Initialize WaveSurfer instance only once (if not already initialized)
    if (!waveSurferRef.current) {
      //   console.log("Initializing WaveSurfer instance");
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#656565",
        progressColor: "#fff",
        // cursorColor: "#656565",
        height: height || 50,
        responsive: true,
        barWidth: 1,
        cursorWidth: 0,
        backend: "MediaElement",
        normalize: true,
        barHeight: 10,
        barGap: 0.0001,
        barRadius: 10,
        plugins: [
          HoverPlugin.create({
            lineColor: "#000", // Color of the hover line
            labelBackground: "#fff", // Background color for the hover time label
            labelColor: "#000", // Font color for the hover time label
            fontSize: 12, // Font size for the hover time label
            showTime: true,
            position: "bottom",
            followCursor: true,
          }),
        ],
      });

      // console.log(jsonData, "90");

      if (
        jsonData &&
        Array.isArray(jsonData.data) &&
        jsonData.data.length > 0
      ) {
        console.log("Using JSON peak data");
        waveSurferRef.current.load(trackUrl, jsonData.data);
      } else {
        console.log("Using URL without peak data");
        waveSurferRef.current.load(trackUrl);
      }
      // waveSurferRef.current.load(trackUrl);

      waveSurferRef.current.on("loading", (progress) => {
        // console.log(`true loading: ${progress}`);

        // console.log("true loading");
        if (progress < 100 && !isLoading) {
          setIsLoading(true); // Only set to true if it's not already
        }
        if (progress === 100 && isLoading) {
          setIsLoading(false); // Only set to false when fully loaded
        }
      });

      waveSurferRef.current.on("ready", (progress) => {
        // console.log(`true ready: ${progress}`);
        //   console.log("true ready");
        //   if (isLoading) {
        //     setIsLoading(false);
        //   }
        waveSurferRef.current.play(); // Automatically start playback
        setIsPlaying(true); // Update state to reflect playback
        onPlayPauseChange(true); // Notify parent about the playback state
        setIsLoading(false);
      });

      // Handle errors during loading
      waveSurferRef.current.on("error", (e) => {
        console.error("WaveSurfer Error:", e);
      });
    }
  }, [trackUrl]); // This effect depends on trackUrl

  useEffect(() => {
    if (waveSurferRef.current) {
      if (play && !isLoading) {
        if (!isPlaying) {
          waveSurferRef.current.play(); // Resume or start the track
          setIsPlaying(true);
          onPlayPauseChange(true); // Notify parent
        }
      } else if (!play && isPlaying) {
        waveSurferRef.current.pause(); // Pause the track
        setIsPlaying(false);
        onPlayPauseChange(false); // Notify parent
      }
    }
  }, [play, isLoading, isPlaying, onPlayPauseChange]);

  const togglePlayPause = () => {
    if (waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.pause();
        setIsPlaying(false);
        onPlayPauseChange(false); // Notify parent
      } else {
        waveSurferRef.current.play();
        setIsPlaying(true);
        onPlayPauseChange(true); // Notify parent
      }
    }
  };

  const skipForward = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.skip(10);
    }
  };

  const skipBackward = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.skip(-10);
    }
  };

  return (
    <div className="waveform-container">
      {/* player controls */}
      {showControls && (
        <div className="player-controls">
          <TbRewindBackward10
            cursor={"pointer"}
            size={22}
            color="#fff"
            onClick={skipBackward}
          />
          {isPlaying ? (
            <IoIosPause
              cursor={"pointer"}
              size={40}
              color="#fff"
              onClick={() => {
                togglePlayPause();
                setIsPlaying(false); // Ensure state updates correctly
              }}
            />
          ) : (
            <IoIosPlay
              cursor={"pointer"}
              size={40}
              color="#fff"
              onClick={() => {
                togglePlayPause();
                setIsPlaying(true); // Ensure state updates correctly
              }}
            />
          )}{" "}
          <TbRewindForward10
            cursor={"pointer"}
            size={22}
            color="#fff"
            onClick={skipForward}
          />
        </div>
      )}

      {/* Waveform container */}
      <div style={{ marginLeft: marginLeft || "30px" }} className="waveform">
        {/* WaveSurfer container */}
        <div ref={waveformRef} style={{ width: "100%", height: "100%" }}></div>

        {/* Loading indicator */}
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "16px",
              color: "#fff",
            }}
          >
            Loading...
          </div>
        )}
      </div>
    </div>
  );
};

export default Waveform;
