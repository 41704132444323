import React, { useEffect } from "react";
import styles from "../styles/CategoryListing.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../utils/redux/trackFiltersSlice";
import { Link } from "react-router-dom";
import CustomImg from "../components/CustomImg/CustomImg";

const MoodsListing = () => {
  const dispatch = useDispatch(); // action to refresh the filters mood, genre, usecase
  const { useCases, status } = useSelector((state) => state.trackFilters);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  const categories = {
    useCase: useCases.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
  };
  // console.log(categories, "useCases");

  // Dynamically generate alluseCases from categories
  const alluseCases = categories.useCase.map((useCase, index) => ({
    id: useCase.id,
    image: randomDefaultImage("square"),
    heading: useCase.title,
    slug: useCase.slug,
  }));

  // console.log(alluseCases, "alluseCases");

  // Utility to pick 4 random useCase from useCases
  // const pickRandomUseCases = (useCases, count = 4) => {
  //   const shuffled = [...useCases].sort(() => 0.5 - Math.random());
  //   return shuffled.slice(0, count);
  // };

  // Utility to filter useCases by title
  const pickMoodsByTitle = (useCases, titles) => {
    const titleSet = new Set(titles.map((title) => title.toLowerCase()));
    return useCases.filter((useCase) =>
      titleSet.has(useCase.heading.toLowerCase())
    );
  };

  // Randomly picked useCases
  // const randomUseCase = pickRandomUseCase(alluseCases);

  // Specifically picked useCases by title
  const specificTitles = ["Jingles", "Kids", "Movies", "Sports"]; // Example titles
  const specificUseCases = pickMoodsByTitle(alluseCases, specificTitles);

  const popularUsecases = specificUseCases; // Set popularUsecase as randomly picked usecase or specificusecase based on requirement

  // Function to find a useCases by title for main banner
  // Function to find useCases by title
  const findUsecaseByTitle = (useCases, title) => {
    return useCases.find(
      (useCase) => useCase.heading.toLowerCase() === title.toLowerCase()
    );
  };

  // Example: Find "Shows" usecase
  // const specificUseCase = findUsecaseByTitle(alluseCases, "Shows");
  const specificUseCase = 'Shorts / Reels';

  return (
    <div className={styles.categoriesListingContainer}>
      {/* category page description */}
      <div className={`${styles.categoriesListingDescription} ${styles.outer}`}>
        <h1 className={styles.categoriesHeading}>Shop By Use Cases</h1>
        <h2 className={styles.categoriesSubHeading}>
        Looking for something unique to soundtrack your content? Explore tracks for over 30+ popular content use cases.
        </h2>
      </div>
      {/* category main banner */}
      <div
        style={{
          backgroundImage: `url(${randomDefaultImage("track")})`,
        }}
        className={`${styles.categoriesListingBanner} ${styles.inner}`}
      >
        <h1 className={styles.categoriesBannerHeading}>
          {/* {specificUseCase ? specificUseCase.heading : "Category Name"} */}
          {specificUseCase}
        </h1>
        <h2 className={styles.categoriesBannerSubHeading}>
        Short and snappy music tracks to create engaging short-form content. Use cases: Instagram Reels and YouTube Shorts.
        </h2>
        <Link
          to={`/categories/${encodeURIComponent(
            specificUseCase ? specificUseCase.slug : ""
          )}/${specificUseCase ? specificUseCase.id : ""}`}
        >
          <CustomButton
            text="Explore Track"
            backgroundColor="#fff"
            borderRadius="50px"
            borderColor="none"
            width="182px"
            height="37px"
            textColor="#C6349B"
            fontSize="16px"
            fontFamily="Raleway"
            fontWeight="bold"
            iconColor="#C6349B"
            icon={<IoIosArrowForward />}
            justifyContent="space-between"
          />
        </Link>
      </div>
      {/* category categories section */}
      <div className={`${styles.popularcategoriesContainer} ${styles.outer}`}>
        <h1 className={styles.popularcategoriesHeading}>Popular Use Cases</h1>
        <div className={styles.popularcategoriesCards}>
          {/* for handling more card css is added just update the slice value */}
          {popularUsecases.slice(0, 4).map((usecase) => (
            <Link
              // key={index}
              to={`/categories/${encodeURIComponent(usecase.slug)}/${
                usecase.id
              }`}
            >
              <div key={usecase.id} className={styles.popularcategoriesCard}>
                {/* <img
                  src={usecase.image}
                  alt={usecase.heading}
                  className={styles.popularcategoriesCardImage}
                /> */}
                <CustomImg
                  type="cat"
                  id={usecase.id}
                  alt={usecase.heading}
                  imgClass={styles.popularcategoriesCardImage}
                  src=""
                >
                </CustomImg>
                <h1 className={styles.popularcategoriesCardHeading}>
                  {usecase.heading}
                </h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* all category section */}
      <div className={`${styles.popularcategoriesContainer} ${styles.outer}`}>
        <h1 className={styles.popularcategoriesHeading}>All Use Cases</h1>
        <div className={styles.allcategoriesCards}>
          {/* for handling more card css is added just update the slice value */}
          {alluseCases.map((usecase) => (
            <Link
              // key={index}
              to={`/categories/${encodeURIComponent(usecase.slug)}/${
                usecase.id
              }`}
            >
              <div key={usecase.id} className={styles.allcategoriesCard}>
                {/* <img
                  src={usecase.image}
                  alt={usecase.heading}
                  className={styles.allcategoriesCardImage}
                /> */}
                <CustomImg
                  type="cat"
                  id={usecase.id}
                  alt={usecase.heading}
                  imgClass={styles.allcategoriesCardImage}
                  src=""
                >
                </CustomImg>
                <h1 className={styles.allcategoriesCardHeading}>
                  {usecase.heading}
                </h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoodsListing;
