import React, { useState, useEffect } from "react";
import "./TrackFilter.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../../../utils/redux/trackFiltersSlice";
import { getSearchResult } from "../../../service/SearchService";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import randomDefaultImage from "../../../utils/RandomDefaultImage";
import getDirectLink from "../../../utils/AudioUrlFormated";

const TrackFilter = ({ onFiltersApplied, isFilterApplied, onClearFilters }) => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [selectedUseCases, setSelectedUseCases] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedMoods, setSelectedMoods] = useState([]);
  // const [isFilterApplied, setIsFilterApplied] = useState(false);

  const dispatch = useDispatch();

  const { genres, useCases, moods, instruments, languages, status } =
    useSelector((state) => state.trackFilters);

  // Fetch filters when component mounts
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  // Map Redux filters to dropdown-compatible format
  const mapFilters = (filters) =>
    filters.map((filter) => ({
      value: filter.value,
      label: filter.label,
    }));

  const languageFilters = mapFilters(languages);
  const instrumentFilters = mapFilters(instruments);
  const useCasesFilters = mapFilters(useCases);
  const genreFilters = mapFilters(genres);
  const moodsFilters = mapFilters(moods);

  // Handle applying filters
  const handleFilterApply = async () => {
    try {
      const filters = {
        languages: selectedLanguages.map((item) => item.label),
        instruments: selectedInstruments.map((item) => item.label),
        useCases: selectedUseCases.map((item) => item.label),
        genres: selectedGenres.map((item) => item.label),
        moods: selectedMoods.map((item) => item.label),
      };

      const query = Object.values(filters)
        .filter((values) => values.length > 0) // Remove empty filters
        .map((values) => values.join(","))
        .join(",");

      const response = await getSearchResult(query);
      const filteredResults = response.filter(
        (item) => item["asset-type"] === "music"
      );

      //   console.log(filteredResults, "filteredResults");

      const formattedResults = filteredResults.flatMap((result) => {
        // Use flatMap to flatten the array

        // Make sure the result contains the 'data' array before mapping
        return result.data.map((track) => {
          //   console.log(track, "track");

          // Check if 'tracks_artist_role' exists and parse it correctly
          const artistNames = track.tracks_artist_role
            ? JSON.parse(track.tracks_artist_role)
                .map((role) => role.artist.name)
                .join(", ")
            : "No artists available";

          return {
            imageUrl:
              track.image_url != "" && track.image_url != null
                ? track.image_url
                : randomDefaultImage("track"),
            name: track.name,
            name_slug: track.name_slug,
            artistNames: artistNames,
            trackCode: track.track_code,
            artistNames: artistNames,
            trackUrl:
              track.mp3Link || getDirectLink(track.link) || track.waveformLink,
            SKU: [
              {
                costPrice: 50000,
                sellingPrice: 40000,
                gstPercent: 18,
                maxUsage: 3,
              },
            ],
          };
        });
      });

      //   console.log("Formatted Results:", formattedResults);

      // Pass filtered results back to parent
      onFiltersApplied(formattedResults);
      // setIsFilterApplied(true); // Set filter applied to true
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  const handleClearFilters = () => {
    // Reset all selected filters
    setSelectedLanguages([]);
    setSelectedInstruments([]);
    setSelectedUseCases([]);
    setSelectedGenres([]);
    setSelectedMoods([]);
  };

  return (
    <div className="filter-contents">
      <div className="filter-options">
        <div className="filter-track-title">
          <p>Filters</p>
          {isFilterApplied ? (
            <span
              onClick={() => {
                handleClearFilters(); // Reset local state
                onClearFilters(); // Notify parent to clear filters
              }}
            >
              Clear
            </span>
          ) : (
            <span onClick={handleFilterApply}>Apply</span>
          )}
        </div>
      </div>
      <div className="filter-artist">
        <p>Languages</p>
        <CustomDropdown
          options={languageFilters}
          placeholder="Languages"
          isMulti
          onChange={setSelectedLanguages}
          value={selectedLanguages}
        />
      </div>
      <div className="filter-artist">
        <p>Instruments</p>
        <CustomDropdown
          options={instrumentFilters}
          placeholder="Instruments"
          isMulti
          onChange={setSelectedInstruments}
          value={selectedInstruments}
        />
      </div>
      <div className="filter-artist">
        <p>Use Cases</p>
        <CustomDropdown
          options={useCasesFilters}
          placeholder="Use Cases"
          isMulti
          onChange={setSelectedUseCases}
          value={selectedUseCases}
        />
      </div>
      <div className="filter-artist">
        <p>Genres</p>
        <CustomDropdown
          options={genreFilters}
          placeholder="Genres"
          isMulti
          onChange={setSelectedGenres}
          value={selectedGenres}
        />
      </div>
      <div className="filter-artist">
        <p>Moods</p>
        <CustomDropdown
          options={moodsFilters}
          placeholder="Moods"
          isMulti
          onChange={setSelectedMoods}
          value={selectedMoods}
        />
      </div>
    </div>
  );
};

export default TrackFilter;
