import React, { useEffect } from "react";
import styles from "../styles/MoodsListing.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../utils/redux/trackFiltersSlice";
import { Link } from "react-router-dom";
import CustomImg from "../components/CustomImg/CustomImg";

const MoodsListing = () => {
  const dispatch = useDispatch(); // action to refresh the filters mood, genre, usecase
  const { moods, status } = useSelector((state) => state.trackFilters);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  const categories = {
    moods: moods.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
  };
  // console.log(categories, "moods");

  // Dynamically generate allMoods from categories
  const allMoods = categories.moods.map((mood, index) => ({
    id: mood.id,
    image: randomDefaultImage("square"),
    heading: mood.title,
    slug: mood.slug,
  }));

  // console.log(allMoods, "allMoods");

  // Utility to pick 4 random moods from allMoods
  // const pickRandomMoods = (moods, count = 4) => {
  //   const shuffled = [...moods].sort(() => 0.5 - Math.random());
  //   return shuffled.slice(0, count);
  // };

  // Utility to filter moods by title
  const pickMoodsByTitle = (moods, titles) => {
    const titleSet = new Set(titles.map((title) => title.toLowerCase()));
    return moods.filter((mood) => titleSet.has(mood.heading.toLowerCase()));
  };

  // Randomly picked moods
  // const randomMoods = pickRandomMoods(allMoods);

  // Specifically picked moods by title
  const specificTitles = ["Groovy", "Celebratory", "Energetic", "Spiritual"]; // Example titles
  const specificMoods = pickMoodsByTitle(allMoods, specificTitles);

  const popularMoods = specificMoods; // Set popularMoods as randomly picked moods or specificMoods based on requirement

  // Function to find a mood by title for main banner
  // Function to find mood by title
  const findMoodByTitle = (moods, title) => {
    return moods.find(
      (mood) => mood.heading.toLowerCase() === title.toLowerCase()
    );
  };

  // Example: Find "Badass" mood
  const specificMood = findMoodByTitle(allMoods, "Romantic");
  // console.log(specificMood.slug, "specificMood");

  return (
    <div className={styles.moodsListingContainer}>
      {/* moods page description */}
      <div className={`${styles.moodsListingDescription} ${styles.outer}`}>
        <h1 className={styles.moodsHeading}>Shop By Moods</h1>
        <h2 className={styles.moodsSubHeading}>
          Don't miss out on this trend-setting opportunity! For a limited time,
          you can Don't miss out on this trend-setting opportunity! For a
          limited time, you can.....
        </h2>
      </div>
      {/* mood main banner */}
      <div
        style={{
          backgroundImage: `url(${randomDefaultImage("track")})`,
        }}
        className={`${styles.moodsListingBanner} ${styles.inner}`}
      >
        <h1 className={styles.moodsBannerHeading}>
          {specificMood ? specificMood.heading : "Moods Name"}
        </h1>
        <h2 className={styles.moodsBannerSubHeading}>
          {specificMood ? specificMood.moodsSubHeadingheading : "Moods Name"}
        </h2>
        <Link
          to={`/moods/${encodeURIComponent(
            specificMood ? specificMood.slug : ""
          )}/${specificMood ? specificMood.id : ""}`}
        >
          <CustomButton
            text="Explore Track"
            backgroundColor="#fff"
            borderRadius="50px"
            borderColor="none"
            width="182px"
            height="37px"
            textColor="#C6349B"
            fontSize="16px"
            fontFamily="Raleway"
            fontWeight="bold"
            iconColor="#C6349B"
            icon={<IoIosArrowForward />}
            justifyContent="space-between"
          />
        </Link>
      </div>
      {/* popular moods section */}
      <div className={`${styles.popularMoodsContainer} ${styles.outer}`}>
        <h1 className={styles.popularMoodsHeading}>Popular Moods</h1>
        <div className={styles.popularMoodsCards}>
          {/* for handling more card css is added just update the slice value */}
          {popularMoods.slice(0, 4).map((mood) => (
            <Link
              // key={index}
              to={`/moods/${encodeURIComponent(mood.slug)}/${mood.id}`}
            >
              <div key={mood.id} className={styles.popularMoodsCard}>
                {/* <img
                  src={mood.image}
                  alt={mood.heading}
                  className={styles.popularMoodsCardImage}
                /> */}
                <CustomImg
                  type="mood"
                  id={mood.id}
                  alt={mood.heading}
                  imgClass={styles.popularMoodsCardImage}
                  src=""
                >
                </CustomImg>
                <h1 className={styles.popularMoodsCardHeading}>
                  {mood.heading}
                </h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* all moods section */}
      <div className={`${styles.popularMoodsContainer} ${styles.outer}`}>
        <h1 className={styles.popularMoodsHeading}>All Moods</h1>
        <div className={styles.allMoodsCards}>
          {/* for handling more card css is added just update the slice value */}
          {allMoods.map((mood) => (
            <Link
              // key={index}
              to={`/moods/${encodeURIComponent(mood.slug)}/${mood.id}`}
            >
              <div key={mood.id} className={styles.allMoodsCard}>
              <CustomImg
                  type="mood"
                  id={mood.id}
                  alt={mood.heading}
                  imgClass={styles.allMoodsCardImage}
                  src=""
                >
                </CustomImg>
                {/* <img
                  src={mood.image}
                  alt={mood.heading}
                  className={styles.allMoodsCardImage}
                /> */}
                <h1 className={styles.allMoodsCardHeading}>{mood.heading}</h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoodsListing;
