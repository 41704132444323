import React, { useState, useRef, useEffect } from "react";
import styles from "../styles/Login.module.css";
import CustomInput from "../components/CustomInput/CustomInput";
import { IoClose } from "react-icons/io5";
import CustomDropdown from "../components/CustomDropdown/CustomDropdown";
import CustomButton from "../components/CustomButton/CustomButton";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../utils/firebase/remoteConfig";
import { useAuth } from "../utils/AuthContext";

const Login = ({ isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen); // Sync with parent initially
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyOtpM, setVerifyOtpM] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [mobileVerify, setMobileVerify] = useState(false);
  const inputRefs = useRef([]);
  const [mobileLogin, setMobileLogin] = useState(false);
  const [gmailLogin, setGmailLogin] = useState(false);
  const [profileVerify, setProfileVerify] = useState(false);
  const [loginMode, setLoginMode] = useState(true);
  const [profile, setProfile] = useState(false);
  const [email, setEmail] = useState(""); // Store email input
  const [emailError, setEmailError] = useState(""); // Store email validation error
  const { authConfig, login } = useAuth();

  useEffect(() => {
    //gettign the login details from the config
    const fetchRemoteConfig = async () => {
      // console.log("called");

      try {
        // Fetch and activate remote config values
        await fetchAndActivate(remoteConfig); // This waits for the config to be fetched

        // Retrieve the JSON string from Remote Config
        const jsonString = getValue(remoteConfig, "auth").asString();
        // console.log(jsonString, "auth info");

        // Parse the JSON string into a JavaScript object
        const parsedConfig = JSON.parse(jsonString);
        // console.log("Parsed Config:", parsedConfig);
      } catch (error) {
        console.error("Error fetching remote config:", error);
      }
    };
    fetchRemoteConfig();
  }, []);

  // Sync local state when parent changes `isOpen`
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setIsModalOpen(false); // Close modal locally
    resetState(); // Reset state when modal is closed
    onClose(); // Notify the parent to close the modal
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and prevent any non-digit characters
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleGetOtp = () => {
    // Log or use the phone number value
    // console.log("Phone Number:", phoneNumber);
    // Add your logic here for sending the OTP
    setVerifyOtpM(true);
  };

  const handleOtpChange = (index, value) => {
    if (!/^\d?$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus(); // Move to the next input box
    }
  };

  const verifyOtp = () => {
    if (!authConfig) {
      console.error("Auth configuration not loaded.");
      return;
    }

    const user = authConfig[phoneNumber];
    if (user && user.otps.includes(otp.join(""))) {
      // console.log("Login successful!");
      login(); // Update global auth state
      setMobileLogin(true);
      setProfile(true);
      setLoginMode(false);
      // onClose();
    } else {
      console.error("Invalid OTP or phone number.");
    }
  };

  const handleBackspace = (index) => {
    if (index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus(); // Move to the previous input box
    }
  };

  const resetState = () => {
    setPhoneNumber("");
    setVerifyOtpM(false);
    setOtp(new Array(6).fill(""));
    setMobileVerify(false);
    setMobileLogin(false);
    setGmailLogin(false);
    setProfileVerify(false);
    setLoginMode(true);
    setProfile(false);
    setEmail(""); // Clear email state
    setEmailError(""); // Clear email error
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email with regex
    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError(""); // Clear error if email is valid
    }
  };

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // dropdown values
  const options = [
    { value: 'agency', label: 'Agency' },
    { value: 'brand', label: 'Brand' },
    { value: 'content-studio', label: 'Content Studio' },
    { value: 'independent-filmmaker', label: 'Independent Filmmaker' },
    { value: 'individual-creator-influencer', label: 'Individual Creator / Influencer' },
    { value: 'ott-platform', label: 'OTT Platform' },
    { value: 'production-house', label: 'Production House' },
    { value: 'radio-station', label: 'Radio Station' },
    { value: 'tv-channel', label: 'TV Channel' },
    { value: 'Other', label: 'Other' },
  ];

  if (!isModalOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        {/* login with otp and gmail section */}
        {loginMode && (
          <div className={styles.innerContent}>
            {/* <div onClick={closeModal} className={styles.closeIconMain}>
              <IoClose
                cursor="pointer"
                onClick={() => !isOpen}
                size={24}
                color="#000"
              />
            </div> */}
            {verifyOtpM === false ? (
              <div className={styles.login}>
                <p className={styles.loginText}>Log In</p>
                <div className={styles.phoneInput}>
                  <select className={styles.countryCode}>
                    <option value="+91">+91</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className={styles.phoneNumber}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={10}
                  />
                </div>
                <button className={styles.getOtpButton} onClick={handleGetOtp}>
                  Get OTP
                </button>
                <div className={styles.separator}>
                  <span>OR</span>
                </div>
                <button
                  // onClick={() => {
                  //   setProfile(true);
                  //   setLoginMode(false);
                  //   setGmailLogin(true);
                  // }}
                  className={styles.googleButton}
                >
                  <img
                    src="https://www.gstatic.com/images/branding/product/1x/gsa_512dp.png"
                    alt="Google"
                    className={styles.googleIcon}
                  />
                  Sign In With Google
                </button>
              </div>
            ) : (
              // login with otp verify section
              <div className={styles.login}>
                <p className={styles.loginText}>Verify OTP</p>
                <div className={styles.checkboxContainer}>
                  <div className={styles.otpNumber}>
                    <p className={styles.otp}>OTP sent to +91-{phoneNumber}</p>
                    <p
                      onClick={() => {
                        setVerifyOtpM(false);
                        setOtp(new Array(6).fill("")); // Clear the OTP inputs
                      }}
                      className={styles.edit}
                    >
                      Edit
                    </p>
                  </div>
                  <div className={styles.otpContainer}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        className={`${styles.otpInput} ${
                          digit ? styles.otpFilled : ""
                        }`}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") handleBackspace(index);
                        }}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  <p className={styles.resend}>
                    Didn't get a code? {""}
                    <span className={styles.resendSpan}>
                      {""}Click here to resend
                    </span>
                  </p>
                  <button
                    onClick={() => {
                      // call the verfiy otp func here
                      verifyOtp();
                    }}
                    className={styles.getOtpButton}
                  >
                    Verify
                  </button>
                </div>
              </div>
            )}
            <div className={styles.privacy}>
              By continuing, you agree to Hoopr Smash{" "}
              <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </div>
          </div>
        )}
        {profile && (
          // profile info section
          <div className={styles.profileContent}>
            <div onClick={closeModal} className={styles.closeIcon}>
              <IoClose
                cursor="pointer"
                onClick={() => !isOpen}
                size={24}
                color="#000"
              />
            </div>
            <p
              style={{ margin: profileVerify ? "0px" : "" }}
              className={styles.profileText}
            >
              Enhance Experience by Completing Profile
            </p>
            <div className={styles.inputContainer}>
              <CustomInput
                fontSize="16px"
                marginTop="5px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #98999C"
                labelTop="15px"
                fontLabelColor="#98999C"
                height="50px"
                placeholder="Name"
                borderRadius="8px"
              />
              {/* profile mobile and email verify based on login mode */}
              <div className={styles.verfiyProfile}>
                {gmailLogin && (
                  <div className={styles.phoneInputProfile}>
                    <select className={styles.countryCode}>
                      <option value="+91">+91</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className={styles.phoneNumber}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      maxLength={10}
                    />
                  </div>
                )}
                {mobileLogin && (
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    width="288px"
                    placeholder="Email Address"
                    borderRadius="8px"
                    containerWidth="auto"
                    onChange={handleEmailChange}
                  />
                )}
                <button
                  onClick={() => setProfileVerify(true)}
                  className={styles.profileVerifyBtn}
                >
                  Verify
                </button>
              </div>
              {profileVerify && (
                <div>
                  <div className={styles.otpContainerProfile}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        className={`${styles.otpInputProfile} ${
                          digit ? styles.otpFilled : ""
                        }`}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") handleBackspace(index);
                        }}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  <p className={styles.resend}>
                    Didn't get a code? {""}
                    <span className={styles.resendSpan}>
                      {""}Click here to resend
                    </span>
                  </p>
                </div>
              )}
              <CustomInput
                fontSize="16px"
                marginTop="5px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #98999C"
                labelTop="15px"
                fontLabelColor="#98999C"
                height="50px"
                placeholder="Company Name"
                borderRadius="8px"
              />
              <CustomDropdown options={options} placeholder="Type of company" />
            </div>
            <div className={styles.buttonContainercp}>
              <CustomButton
                text="Complete Profile"
                backgroundColor="#c6349b"
                borderRadius="50px"
                width="100%"
                height="48px"
                textColor="#fff"
                fontSize="16px"
                fontFamily="Raleway"
                fontWeight="550"
                onClick={closeModal}
              />
            </div>
            <span onClick={closeModal} className={styles.skip}>
              Skip for Now
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
