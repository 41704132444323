import React, { useEffect, useState, useRef } from "react";
import "../styles/MoodsPage.css";
import moodsInfo from "../utils/data/moods";
import { useParams } from "react-router-dom";
import { FiShare2 } from "react-icons/fi";
import CustomButton from "../components/CustomButton/CustomButton";
import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { getSearchResultFaceted } from "../service/SearchService";
import { useSearchParams } from "react-router-dom";
import FilterTrackCards from "../components/CardsComponent/TrackCard/FilterTrackCard";
import getDirectLink from "../utils/AudioUrlFormated";

// import { RiShoppingCart2Line } from "react-icons/ri";
// import { IoIosArrowForward } from "react-icons/io";
// import config from "../utils/config";
// import PackCard from "../components/CardsComponent/PackCard/PackCard";

const MoodsPage = () => {
  const { moodsCode } = useParams(); // Extract the id from the URL
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page for API
  const [hasMoreData, setHasMoreData] = useState(true); // Flag to track if more data is available
  const scrollToTopRef = useRef(null); // Ref for scrolling

  // const [searchParams] = useSearchParams();
  // const [tracksData, setTracksData] = useState([]);
  // const searchKeyword = searchParams.get("searchKeyword");

  useEffect(() => {
    if (moodsCode) {
      // Reset page when keyword changes
      // setPage(1);

      //  RESET LOADING STATE
      // setIsLoading(false);

      // Reset data availability flag
      setHasMoreData(true);

      fetchTracks("", 1, true); // Fetch first page

      // Scroll to top when keyword changes
      if (scrollToTopRef.current) {
        scrollToTopRef.current.scrollTo(0, 0);
      }
    }
  }, [moodsCode]);

  // Function to load more results (called by FilterTrackCards)
  const loadMoreResults = () => {
    if (!isLoading && hasMoreData) {
      setPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchTracks("", nextPage, false);
        return nextPage;
      });
    }
  };

  const fetchTracks = async (query, pageNum, reset) => {
    try {
      if (!hasMoreData || isLoading) return; // Stop fetching if no more data or already loading
      setIsLoading(true);

      const searchKey = moodsInfo[moodsCode].name
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, "-");
      // const data = await getSearchResult(searchKey);
      const payload = {
        asset_type: "music",
        artists: [],
        bpm: {},
        genres: [],
        languages: [],
        moods: [searchKey],
        playlists: [],
        sfxcategories: [],
        sfxsubcategories: [],
        subgenres: [],
        usecases: [],
      };

      const data = await getSearchResultFaceted("", payload, pageNum);
      if (data?.length === 0) {
        setHasMoreData(false); // No more data available
        setIsLoading(false);
        setPage(() => 1);
        return;
      }

      setResults((prev) => (reset === true ? data : [...prev, ...data]));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching tracks:", error);
    }
  };

  //   console.log(results, "results");

  const tracksData = results.filter((item) => item.type === "music");

  // Create an array of objects with selected properties
  const allTrackList =
    tracksData?.map((track) => ({
      imageUrl:
        track.image_url != "" && track.image_url != null
          ? track.image_url
          : randomDefaultImage("track"),
      name: track.name,
      name_slug: track.name_slug,
      trackCode: track.track_code,
      tracks_artist_role: track.tracks_artist_role,
      SKU: [
        {
          costPrice: 49999,
          sellingPrice: 29999,
          gstPercent: 18,
          maxUsage: 3,
        },
      ],
      artistNames: JSON.parse(track.tracks_artist_role || "[]") // Ensure valid JSON
        // .map((role) => role.artist?.name || "")
        // .join(", ");
        .filter((item) => item.role === "primary")
        .map((item) => item.artist.name)
        .join(", "),
      trackUrl:
        track.mp3_link || getDirectLink(track.link) || track.waveform_link,
    })) || [];

  const moodDataString = moodsInfo[moodsCode]
    ? JSON.stringify(moodsInfo[moodsCode])
    : null;

  // Fetch the data from mood using the id
  // const moodArray = Object.entries(moodDataString).map(([uuid, mood]) => {
  //   return {
  //       uuid: uuid,
  //       name: mood.name,
  //       description: mood.description,
  //       img: mood.img
  //   };
  // });

  const moodData = moodDataString ? JSON.parse(moodDataString) : {};
  // console.log(moodData,'148');

  // Convert it back to an object
  //   console.log(moodData, "moodData");

  // Transform tracksData into trendingTracks
  //   const moodsTracks = (() => {
  //     try {
  //       return (
  //         tracksData[0]?.data?.map((track) => {
  //           const artistNames = JSON.parse(track.tracks_artist_role || "[]") // Ensure valid JSON
  //             // .map((role) => role.artist?.name || "")
  //             // .join(", ");
  //             .filter((item) => item.role === "primary")
  //             .map((item) => item.artist.name)
  //             .join(", ");

  //           return {
  //             image: track.image_url || randomDefaultImage("track"),
  //             title:
  //               track.name?.length > 18
  //                 ? `${track.name.substring(0, 17)}...`
  //                 : track.name || "Untitled",
  //             description:
  //               artistNames.length > 30
  //                 ? `${artistNames.substring(0, 29)}...`
  //                 : artistNames || "Unknown Artist",
  //             buttonText: "Explore",
  //             buttonLink: `#/${track.name_slug || ""}`,
  //             discountPercent: "20%",
  //             costPrice: 69999,
  //             sellingPrice: 49999,
  //             trackCode: track.track_code || "",
  //             name: track.name,
  //             slug: track.name_slug,
  //           };
  //         }) || []
  //       );
  //     } catch (error) {
  //       console.error("Error processing moodsTracks:", error);
  //       return [];
  //     }
  //   })();

  //   console.log(moodsTracks, "moodsTracks");

  //   const packCards = [
  //     {
  //       image: `${config.s3imgUrl}trackimages/sampleimage.png`,
  //       title: "Happy Diwali",
  //       buttonText: "Explore Packs",
  //       buttonLink: "#",
  //       totalTracks: 10,
  //     },
  //     {
  //       image: `${config.s3imgUrl}trackimages/sampleimage.png`,
  //       title: "Happy Diwali",
  //       buttonText: "Explore Packs",
  //       buttonLink: "#",
  //       totalTracks: 12,
  //     },
  //     {
  //       image: `${config.s3imgUrl}trackimages/sampleimage.png`,
  //       title: "Happy Diwali",
  //       buttonText: "Explore Packs",
  //       buttonLink: "#",
  //       totalTracks: 14,
  //     },
  //   ];

  return (
    <div className="mood-parent-container">
      {/* mood details */}
      <div className="mp-container outer-full">
        <div className="inner inner-mp">
          <div className="mp-details-container">
            {/* mp image */}
            {/* <div
              className="mp-image"
              style={{
                backgroundImage: moodData.img
                  ? `url(${moodData.img})`
                  : `url(${randomDefaultImage("square")})`,
                backgroundColor: moodData.img ? "transparent" : "#ece2c3", // Set default background color when image is missing
              }}
            ></div> */}
            <BgImageDiv
              type="mood"
              id={moodsCode}
              divClass="mp-image"
            ></BgImageDiv>
            {/* mp and artist details */}
            <div className="mp-details">
              <div className="mp-names">
                <h1>{moodData.name || "moodName"}</h1>
                <h2>{moodData.description || ""}</h2>
              </div>
            </div>
          </div>
          {/* mp like share button */}
          <div className="mp-action-button">
            <div>
              <CustomButton
                isIconOnly={true}
                backgroundColor="#F3F3F3"
                borderRadius="50px"
                iconColor="#000"
                icon={<FiShare2 />}
                width="34px"
                height="34px"
                iconSize="16px"
              />
            </div>
          </div>
        </div>
      </div>
      {/* mood based tracks section */}
      {/* {moodsTracks.length > 0 ? ( */}
      <div className="catg-now-container outer-full outer-moodsg">
        <div className="inner">
          {/* <h1> */}
          {/* Feeling { moodData.name } Tracks */}
          {/* <span>
                  <img
                    className="catg-icon-header"
                    src={`${config.s3imgUrl}icons/trendwhiteicon.png`}
                    alt="catg"
                  />
                </span> */}
          {/* </h1> */}
          <div className="catg-description-view">
            {/* <h2>
              Don't miss out on this trend-setting opportunity! For a limited
              time, you can...
            </h2> */}
            {/* <div className="catg-viewall">
                  <p>View All</p>
                  <p>
                    <IoIosArrowForward color="#373737" />
                  </p>
                </div> */}
          </div>
          <div className="catg-track-cards">
            {/* {moodsTracks.map((track, index) => (
                <div key={index} className="catg-track-card">
                  <div
                    className="catg-track-card-image"
                    style={{
                      backgroundImage: `url(${track.image})`,
                    }}
                  ></div>
                  <div className="catg-track-card-text">
                    <div className="catg-track-title">
                      <Link
                        to={`/tracks/${encodeURIComponent(track.slug)}/${
                          track.trackCode
                        }`}
                      >
                        <h1>{track.title}</h1>
                      </Link>
                      <h2>{track.description}</h2>
                    </div>
                    <div className="catg-track-price-container">
                      <div className="catg-track-discount-price">
                        <div className="catg-track-discount-amount">
                          <span>₹</span>
                          <p>{track.sellingPrice.toLocaleString()}</p>
                        </div>
                        <div className="catg-track-discount-percentage">
                          {Math.round(
                            ((parseFloat(track.costPrice) -
                              parseFloat(track.sellingPrice)) *
                              100) /
                              parseFloat(track.costPrice)
                          )}
                          % Off
                        </div>
                      </div>
                      <div className="catg-track-cost-price">
                        <span>₹{track.costPrice.toLocaleString()}</span>
                      </div>
                    </div>
                    <div className="catg-explore-track-button">
                      <Link
                        to={`/tracks/${encodeURIComponent(track.slug)}/${
                          track.trackCode
                        }`}
                      >
                        <div>
                          <CustomButton
                            text={track.buttonText}
                            backgroundColor="#fff"
                            borderRadius="50px"
                            borderColor="1px solid rgb(204, 204, 204)"
                            width="186px"
                            height="37px"
                            textColor="#212328"
                            iconColor="#212328"
                            fontSize="16px"
                            fontFamily="Inter"
                            fontWeight="550"
                            icon={<IoIosArrowForward />}
                            justifyContent="space-between"
                          />
                        </div>
                      </Link>
                      <div>
                        <CustomButton
                          icon={<RiShoppingCart2Line />}
                          isIconOnly={true}
                          backgroundColor="#000"
                          iconColor="#fff"
                          borderRadius="50px"
                          height="37px"
                          width="37px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}
            <FilterTrackCards
              filterTrackData={allTrackList}
              onLoadMore={loadMoreResults}
              isLoading={isLoading}
              hasMoreData={hasMoreData}
              scrollToTopRef={scrollToTopRef} // Pass down ref
              trackHeading={moodData.name}
            />
          </div>
        </div>
      </div>
      {/* ) : null} */}
      {/* mood based  packs section */}
      {/* <div className="cp-packs-container outer-full">
        <div className="inner">
          <h1 className="cp-packs-contianer-heading">Packs</h1>
          <div className="cp-packs-description-view">
            <h2>On your first Instagram Trending Track Purchase </h2> */}
      {/* <div className="packs-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div> */}
      {/* </div>
          <div className="inner">
            <PackCard packCards={packCards} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default MoodsPage;
