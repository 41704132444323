// utils/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "./firebase/remoteConfig"; // Adjust this import path as per your project structure.

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authConfig, setAuthConfig] = useState(null);
  const [loading, setLoading] = useState(true); // New state for loading

  useEffect(() => {
    const fetchAuthConfig = async () => {
      try {
        await fetchAndActivate(remoteConfig); // Fetch and activate Remote Config
        const jsonString = getValue(remoteConfig, "auth").asString(); // Fetch the auth config
        const parsedConfig = JSON.parse(jsonString);
        setAuthConfig(parsedConfig); // Save config globally
      } catch (error) {
        console.error("Failed to fetch auth config:", error);
      } finally {
        // Restore login state from session storage
        const storedAuthState = sessionStorage.getItem("isAuthenticated");
        if (storedAuthState === "true") {
          setIsAuthenticated(true);
        }
        setLoading(false); // Mark loading as complete
      }
    };

    fetchAuthConfig();
    // Restore login state from session storage
    const storedAuthState = sessionStorage.getItem("isAuthenticated");
    if (storedAuthState === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  const login = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem("isAuthenticated", "true");
  };

  const logout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem("isAuthenticated");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, authConfig }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
