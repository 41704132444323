import { configureStore } from "@reduxjs/toolkit";
import trackFiltersReducer from "./trackFiltersSlice";
import cartReducer from "./cartSlice";

const store = configureStore({
  reducer: {
    trackFilters: trackFiltersReducer,
    cart: cartReducer,
  },
});

export default store;
