import React, { useState, useContext } from "react";
import styles from "../styles/Checkout.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomInput from "../components/CustomInput/CustomInput";
import { IoIosClose } from "react-icons/io";
import Select from "react-select";
import { FiEdit } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { ToastContext } from "../utils/ToastContext";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../utils/redux/cartSlice";

const Checkout = () => {
  const [billingInfo, setBillingInfo] = useState([]); // user billing info data
  const [isChecked, setIsChecked] = useState(false); // state for checkbox for t&c
  const [defaultBillingChecked, setDefaultBillingChecked] = useState(false); // state for checkbox of default billing info
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [phoneNumber, setPhoneNumber] = useState(""); // phone number
  const [editIndex, setEditIndex] = useState(null); // Track index of the billing info being edited

  // Input states for user info billing
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [pincode, setPincode] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [selectedOption, setSelectedOption] = useState(""); // state to manage the selected user billing details

  const cartItems = useSelector((state) => state.cart.items); // Access cart items from Redux store
  // console.log(cartItems, "cart iten from redux");

  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const navigate = useNavigate(); // Hook to navigate programmatically
  const dispatch = useDispatch();

  // handle check box for t&c
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // handle check box for default billing info
  const handleBillCheckboxChange = () => {
    setDefaultBillingChecked(!defaultBillingChecked);
  };

  // to display the user bill info add modal
  const toggleModal = () => {
    if (!showModal) {
      // Clear form fields only when opening the modal for a new entry
      setCompanyName("");
      setPhoneNumber("");
      setEmail("");
      setAddressLine1("");
      setAddressLine2("");
      setCity(null);
      setState(null);
      setPincode("");
      setGstNumber("");
      setPanNumber("");
      setDefaultBillingChecked(false);
    }
    setShowModal(!showModal);
  };

  // handle phone number input
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and prevent any non-digit characters
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  // function to save user bill info details
  const handleSave = (e) => {
    e.preventDefault();

    // Regex to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const billingData = {
      companyName,
      phoneNumber,
      email,
      addressLine1,
      addressLine2,
      city: city?.value || "",
      state: state?.value || "",
      pincode,
      gstNumber,
      panNumber,
      defaultBillingChecked,
    };

    if (defaultBillingChecked) {
      // Set all others to false if the current one is default
      billingInfo.forEach((info) => (info.defaultBillingChecked = false));
    }

    if (editIndex !== null) {
      // Update existing billing info
      const updatedBillingInfo = [...billingInfo];
      updatedBillingInfo[editIndex] = billingData;
      setBillingInfo(updatedBillingInfo);
    } else {
      // Add new billing info
      setBillingInfo((prevInfo) => [...prevInfo, billingData]);
    }

    // Reset editing state and close modal
    setEditIndex(null);
    setShowModal(false);
  };

  // fucntion handle the edit user billing info
  const handleEditBillingInfo = (index) => {
    const info = billingInfo[index];
    console.log("Editing companyName:", info.companyName); // Log here

    // Populate the form fields with the selected billing info
    setEditIndex(index);
    setCompanyName(info.companyName ? info.companyName : "companyname");
    setPhoneNumber(info.phoneNumber || "");
    setEmail(info.email || "");
    setAddressLine1(info.addressLine1 || "");
    setAddressLine2(info.addressLine2 || "");
    setCity(info.city ? { value: info.city, label: info.city } : null);
    setState(info.state ? { value: info.state, label: info.state } : null);
    setPincode(info.pincode || "");
    setGstNumber(info.gstNumber || "");
    setPanNumber(info.panNumber || "");
    setDefaultBillingChecked(info.defaultBillingChecked || false);
    setShowModal(true);
  };

  // Handler for change event
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePayNow = () => {
    showToast(
      "Purchase Successfull",
      "Your purchase was succesful!",
      "success"
    ); //display toast msg
    dispatch(clearCart());
    navigate("/"); // Navigate after modal closes
  };

  //  city options
  const cityOptions = [
    { value: "mumbai", label: "Mumbai" },
    { value: "patna", label: "Patna" },
    { value: "noida", label: "Noida" },
  ];

  // state options
  const stateOptions = [
    { value: "maharashtra", label: "Maharashtra" },
    { value: "delhi", label: "Delhi" },
    { value: "bihar", label: "Bihar" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "200px", // Custom width
    }),
  };

  return (
    <div className={styles.checkoutContainer}>
      {/* header section */}
      <div className={styles.checkoutHeader}>
        <p className={styles.headerText}>Checkout</p>
      </div>
      <div className={styles.billDContainer}>
        {/* billing details section */}
        <div className={styles.billDetails}>
          {/* details header section */}
          <div className={styles.billDHeader}>
            <p className={styles.billHeading}>Billing Details</p>
            <span className={styles.billSubHeading}>
              Please ensure all details are accurate as they will be reflected
              on your invoice. Complete your information for a seamless billing
              process
            </span>
          </div>
          {/* detail profile information */}
          <div className={styles.profileInfo}>
            <p className={styles.profileHeading}>Profile Info</p>
            <div className={styles.userInfo}>
              <p className={styles.username}>Prashant Saini</p>
              <div className={styles.emailPhone}>
                <span>+91-7666491058</span>
                <p className={styles.bullet}>prashant@gsharp.media</p>
              </div>
            </div>
          </div>
          {/* billing info section */}
          <div className={styles.billInfoContainer}>
            <p className={styles.billInfoHeading}>Billing Info</p>
            {billingInfo.length > 0 ? (
              <>
                {billingInfo.map((info, index) => (
                  <div key={index} className={styles.billInfo}>
                    <div className={styles.billUserInfo}>
                      <input
                        type="radio"
                        value={index}
                        name="billingInfo"
                        checked={selectedOption === index.toString()}
                        onChange={() => setSelectedOption(index.toString())}
                      />
                      <div className={styles.bsiName}>
                        <p className={styles.username}>{info.companyName}</p>
                        <div className={styles.emailPhone}>
                          <span>{info.phoneNumber}</span>
                          <p className={styles.bullet}>{info.email}</p>
                        </div>
                      </div>
                      <div
                        onClick={() => handleEditBillingInfo(index)}
                        className={styles.edit}
                      >
                        <FiEdit cursor="pointer" color="#464646" size={18} />
                      </div>
                    </div>
                    <div className={styles.gstpanContainer}>
                      <div className={styles.gstpanBillInfo}>
                        <p className={styles.gstpanHeading}>GSTIN:</p>
                        <span className={styles.gstpanNumber}>
                          {info.gstNumber || "N/A"}
                        </span>
                      </div>
                      <div className={styles.gstpanBillInfo}>
                        <p className={styles.gstpanHeading}>PAN:</p>
                        <span className={styles.gstpanNumber}>
                          {info.panNumber || "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                <div onClick={toggleModal} className={styles.addNewBillingBtn}>
                  Add New +
                </div>
              </>
            ) : (
              <div onClick={toggleModal} className={styles.billInfoadd}>
                Add Billing Info +
              </div>
            )}
          </div>
          {/* order summary section */}
          <div className={styles.orderSumContainer}>
            <p className={styles.oscHeader}>Order Summary</p>
            {cartItems.map((item, index) => (
              <div key={index} className={styles.oscContent}>
                <div>
                  <div className={styles.trackInfo}>
                    <p className={styles.trackName}>{item.preText}</p>
                    <div className={styles.trackQutantity}>{item.quantity}</div>
                  </div>
                  <span className={styles.artistName}>{item.secText}</span>
                </div>
                <div>
                  <p className={styles.trackPrice}>
                    ₹
                    {(
                      parseInt(item.sp.replace(/,/g, ""), 10) * item.quantity
                    ).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.billSummary}>
          <div className={styles.billSumInfo}>
            <p className={styles.bsiText}>Billing Summary</p>
            <div className={styles.bsiSection}>
              <div className={styles.bsiContent}>
                <p className={styles.bsiHeading}>Subtotal</p>
                <span className={styles.bsiSubHeading}>
                  ₹
                  {Number(
                    (
                      cartItems.reduce(
                        (subtotal, item) =>
                          subtotal +
                          parseInt(item.sp.replace(",", ""), 10) *
                            (item.quantity || 1),
                        0
                      ) / 1.18
                    ).toFixed(2) // Ensure two decimal places for precision
                  ).toLocaleString("en-IN")}
                </span>
              </div>
              <div className={styles.bsiContent}>
                <p className={styles.bsiHeading}>TAX (18%GST)</p>
                <span className={styles.bsiSubHeading}>
                  {" "}
                  ₹
                  {Number(
                    (
                      cartItems.reduce(
                        (total, item) =>
                          total +
                          parseInt(item.sp.replace(",", ""), 10) *
                            item.quantity,
                        0
                      ) -
                      cartItems.reduce(
                        (subtotal, item) =>
                          subtotal +
                          (parseInt(item.sp.replace(",", ""), 10) *
                            (item.quantity || 1)) /
                            1.18,
                        0
                      )
                    ).toFixed(2) // Ensure two decimal places for precision
                  ).toLocaleString("en-IN")}
                </span>
              </div>
            </div>
          </div>
          <hr className={styles.breakLine}></hr>
          <div className={styles.bsiTotalSection}>
            <div className={styles.totalAmt}>
              <div className={styles.totaltext}>Total</div>
              <div className={styles.totalPriceSection}>
                <span className={styles.billTotal}>
                  ₹
                  {Number(
                    cartItems
                      .reduce(
                        (total, item) =>
                          total +
                          parseInt(item.sp.replace(",", "")) * item.quantity,
                        0
                      )
                      .toFixed(2) // Ensure two decimal places for precision
                  ).toLocaleString("en-IN")}
                </span>
                <div className={styles.bsiSaving}>
                  You are saving ₹
                  {cartItems
                    .reduce(
                      (totalSavings, item) =>
                        totalSavings +
                        (parseInt(item.cp.replace(",", "")) -
                          parseInt(item.sp.replace(",", ""))) *
                          item.quantity,
                      0
                    )
                    .toLocaleString("en-IN")}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.tandc}>
            <label className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className={styles.checkbox}
              />
              <span className={styles.checkboxText}>
                I have read and agreed to{" "}
                <a href="/terms" className={styles.link}>
                  Terms
                </a>{" "}
                &{" "}
                <a href="/privacy" className={styles.link}>
                  Privacy Policy
                </a>
              </span>
            </label>
          </div>
          <div className={styles.payBtn}>
            <CustomButton
              text="Pay Now"
              backgroundColor="#C6349B"
              borderRadius="50px"
              width="348px"
              height="50px"
              textColor="#fff"
              fontSize="18px"
              fontFamily="Raleway"
              fontWeight="550"
              onClick={handlePayNow}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <form onSubmit={handleSave} className={styles.modalBackdrop}>
          <div className={styles.modalBackdrop} onClick={toggleModal}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()} // Prevent backdrop click from closing modal
            >
              <div className={styles.modalHeader}>
                <p className={styles.headerText}>Add Billing Information</p>
                <IoIosClose
                  onClick={() => setShowModal(false)}
                  color="#000"
                  cursor="pointer"
                  size={32}
                />
              </div>
              <div className={styles.billingForm}>
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Company / Your Name"
                  borderRadius="8px"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <div className={styles.phoneInputProfile}>
                  <select className={styles.countryCode}>
                    <option value="+91">+91</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className={styles.phoneNumber}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={10}
                  />
                </div>
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Email"
                  borderRadius="8px"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Address Line 1"
                  borderRadius="8px"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Address Line 2"
                  borderRadius="8px"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
                <div className={styles.state}>
                  <Select
                    value={city}
                    onChange={setCity}
                    styles={customStyles}
                    options={cityOptions}
                  />
                  <Select
                    value={state}
                    onChange={setState}
                    styles={customStyles}
                    options={stateOptions}
                  />
                </div>
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Pincode"
                  borderRadius="8px"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
                <div className={styles.gstpan}>
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    placeholder="GSTIN Number"
                    borderRadius="8px"
                    value={gstNumber}
                    onChange={(e) => setGstNumber(e.target.value)}
                  />
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    placeholder="PAN Number"
                    borderRadius="8px"
                    value={panNumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                  />
                </div>
                <div className={styles.billingCheckbox}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={defaultBillingChecked}
                      onChange={handleBillCheckboxChange}
                      className={styles.checkbox}
                    />
                    <span className={styles.checkboxText}>
                      Set as default billing information
                    </span>
                  </label>
                </div>
                <div className={styles.payBtn}>
                  <CustomButton
                    text="Save"
                    backgroundColor="#C6349B"
                    borderRadius="50px"
                    width="399px"
                    height="50px"
                    textColor="#fff"
                    fontSize="18px"
                    fontFamily="Raleway"
                    fontWeight="550"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Checkout;
