import React, { useState, useEffect } from "react";
import "../styles/PackPage.css";
import { FaRegHeart } from "react-icons/fa";
import CustomButton from "../components/CustomButton/CustomButton";
import { RiShoppingCart2Line } from "react-icons/ri";
import { FiShare2 } from "react-icons/fi";
import { getPack } from "../service/PackService";
import { useParams, Link } from "react-router-dom";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { IoIosArrowForward, IoIosPause } from "react-icons/io";

const PackPage = () => {
  const [visibleCount, setVisibleCount] = useState(5);
  const [packData, setPackData] = useState(null);
  const { packCode } = useParams(); // getting the data from the routes

  // Fetch pack data on component mount
  useEffect(() => {
    const fetchPackData = async () => {
      try {
        const data = await getPack(packCode); // Fetch the pack data using the packCode
        console.log(data);

        setPackData(data); // Store the fetched data in state
      } catch (error) {
        console.error("Error fetching pack data:", error);
      }
    };

    fetchPackData(); // Call the fetch function
  }, [packCode]); // Run the effect whenever packCode changes

  const handleViewMore = () => {
    setVisibleCount((prev) => prev + 5); // Show 6 more cards each time
  };

  // Function to calculate discount percentage
  const calculateDiscountPercentage = (sellingPrice, costPrice) => {
    if (!sellingPrice || !costPrice) return 0; // Avoid division by zero
    const discount = ((sellingPrice - costPrice) / costPrice) * 100;
    return Math.round(discount); // Round the result to the nearest integer
  };

  if (!packData) return <div>Loading...</div>; // Display loading state if data is not available
  // console.log(packData);

  const { name, description, trackCount, tracks } = packData.data; // setting the data from the packData object
  // console.log("Rakesh----", packData.data.coverImage);

  return (
    <div className="pd-container">
      {/* pack description and details section */}
      <div className="pd-content outer-full">
        <div className="inner">
          <div
            className="pd-card-img"
            style={{
              // backgroundImage: `url(${packData.data.coverImage == '' || randomDefaultImage('pack')} )`,
              backgroundImage:
                packData.data.coverImage != "" &&
                packData.data.coverImage != null
                  ? `url(${packData.data.coverImage})`
                  : `url(${randomDefaultImage("pack")})`,
            }}
          >
            <div className="pd-like-info">
              <div className="pd-like-icon">
                <FaRegHeart size={24} color="#fff" />
              </div>
              <div className="pd-card-track-total">
                <p>{trackCount}</p>
                <span>TRACKS</span>
              </div>
            </div>
          </div>
          <div className="pd-info">
            <h1 className="pd-info-title">{name}</h1>
            <h2 className="pd-info-description">
              {description.length > 160
                ? description.substring(0, 150) + "..."
                : description}
            </h2>
            {packData.data.markerType === "Packs" ? (
              <div className="pd-info-price">
                <div className="pd-info-price-amount">
                  <div className="pd-discount-amount">
                    <p>₹</p>
                    <span>{packData.data.SKU[0].sellingPrice.toLocaleString("en-IN")}</span>
                  </div>
                  {/* <div className="pd-discount-percentage">-50%</div> */}
                  <div className="pd-discount-percentage">
                    {calculateDiscountPercentage(
                      packData?.data.SKU[0]?.sellingPrice,
                      packData?.data.SKU[0]?.costPrice
                    )}
                    %
                  </div>
                </div>
                <span className="pd-info-price-cost">
                  ₹{packData.data.SKU[0].costPrice.toLocaleString('en-IN')}
                </span>
              </div>
            ) : null}

            {packData.data.markerType === "Packs" ? (
            <div className="pd-info-button">
              <div>
                <CustomButton
                  justifyContent="space-between"
                  buyArrow={true}
                  fontSize="14px"
                  text="Buy Now"
                  backgroundColor="#C6349B"
                  textColor="#ffffff"
                  fontWeight="550"
                  width="188px"
                  height="39px"
                  borderRadius="50px"
                />
              </div>
              <div>
                <CustomButton
                  backgroundColor="#FFFFFF"
                  width="39px"
                  height="39px"
                  borderRadius="50px"
                  iconColor="#000"
                  isIconOnly="true"
                  icon={<RiShoppingCart2Line />}
                />
              </div>
              <div>
                <CustomButton
                  backgroundColor="#FFFFFF"
                  width="39px"
                  height="39px"
                  borderRadius="50px"
                  iconColor="#000"
                  isIconOnly="true"
                  icon={<FiShare2 />}
                />
              </div>
              </div>
              ) : null}
          </div>
        </div>
      </div>
      {/* track list with associated pack */}
      {tracks.length > 0 ? (
        <div className="catg-now-container outer-full bg-black outer-moodsg">
          <div className="inner">
            {/* <h1> */}
            {/* Feeling { moodData.name } Tracks */}
            {/* <span>
                  <img
                    className="catg-icon-header"
                    src={`${config.s3imgUrl}icons/trendwhiteicon.png`}
                    alt="catg"
                  />
                </span> */}
            {/* </h1> */}
            {/* <div className="catg-description-view"> */}
            {/* <h2>
                Don't miss out on this trend-setting opportunity! For a limited
                time, you can...
              </h2> */}
            {/* <div className="catg-viewall">
                  <p>View All</p>
                  <p>
                    <IoIosArrowForward color="#373737" />
                  </p>
                </div> */}
            {/* </div> */}
            <div className="pp-track-heading">
              <h2>Tracks included in {name}</h2>
            </div>
            <div className="pp-track-cards">
              {tracks.slice(0, visibleCount).map((track, index) => (
                <div key={index} className="pp-track-card">
                  <div
                    className="pp-track-card-image"
                    style={{
                      backgroundImage:
                        track.imageUrl != "" && track.imageUrl != null
                          ? `url(${track.imageUrl})`
                          : `url(${randomDefaultImage("track")})`,
                    }}
                  ></div>
                  <div className="pp-track-card-text">
                    <div className="pp-track-title">
                      <Link
                        to={`/tracks/${encodeURIComponent(track.name_slug)}/${
                          track.trackCode
                        }`}
                      >
                        <h1>{track.name}</h1>
                      </Link>
                      <h2>
                        {track.primaryArtists
                          .map((artist) => artist.artistName)
                          .join(", ")
                          .substring(0, 30)}
                      </h2>
                    </div>
                    {/* <div className="catg-track-price-container">
                      <div className="catg-track-discount-price">
                        <div className="catg-track-discount-amount">
                          <span>₹</span>
                          <p> {track.SKU[0]?.costPrice.toLocaleString() || "49,999"} </p>
                        </div>
                        { parseFloat(track.SKU[0]?.sellingPrice) < parseFloat(track.SKU[0]?.costPrice)
                          ?
                            <div className="catg-track-discount-percentage">
                              {Math.round(
                                ((parseFloat(track.SKU[0]?.costPrice) -
                                  parseFloat(track.SKU[0]?.sellingPrice)) *
                                  100) /
                                  parseFloat(track.SKU[0]?.costPrice)
                              )}
                              % Off
                            </div>
                         : ""
                        }
                      </div>
                      {parseFloat(track.SKU[0]?.sellingPrice) < parseFloat(track.SKU[0]?.costPrice) ?
                        <div className="catg-track-cost-price">
                          <span>₹{track.SKU[0]?.costPrice.toLocaleString()}</span>
                        </div>
                        : ""
                      }
                    </div> */}
                    {/* <div className="catg-explore-track-button">
                      <Link
                        to={`/tracks/${encodeURIComponent(track.name_slug)}/${
                          track.trackCode
                        }`}
                      >
                        <div>
                          <CustomButton
                            text="Explore"
                            backgroundColor="#fff"
                            borderRadius="50px"
                            borderColor="1px solid rgb(204, 204, 204)"
                            width="186px"
                            height="37px"
                            textColor="#212328"
                            iconColor="#212328"
                            fontSize="16px"
                            fontFamily="Inter"
                            fontWeight="550"
                            icon={<IoIosArrowForward />}
                            justifyContent="space-between"
                          />
                        </div>
                      </Link>
                      <div>
                        <CustomButton
                          icon={<RiShoppingCart2Line />}
                          isIconOnly={true}
                          backgroundColor="#000"
                          iconColor="#fff"
                          borderRadius="50px"
                          height="37px"
                          width="37px"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
      {/* <div className="pt-list-container inner">
        {tracks.slice(0, visibleCount).map((track, index) => (
          <div key={index} className="pt-container">
            <div
              className="pt-img"
              style={{
                backgroundImage:
                  track.imageUrl != "" && track.imageUrl != null
                    ? `url(${track.imageUrl})`
                    : `url(${randomDefaultImage("track")})`,
              }}
            ></div>
            <div className="pt-info">
              <div className="pt-info-title">
                <div>
                  <h1 className="pt-info-title-heading">{track.name}</h1>
                  <h2 className="pt-info-title-subheading">
                    {track.primaryArtists
                      .map((artist) => artist.artistName)
                      .join(", ")}
                  </h2>
                </div>
                <p className="pt-info-title-price">
                  ₹{track.SKU[0]?.costPrice}{" "}
                </p>
              </div>
              <div className="pt-info-icon">
                <FaRegHeart color="#bcbcbc" size={24} />
              </div>
            </div>
          </div>
        ))}
      </div> */}

      {/* View More Button */}
      {visibleCount < tracks.length && (
        <div className="viewmore-btn" onClick={handleViewMore}>
          View More
        </div>
      )}
    </div>
  );
};

export default PackPage;
